import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

import { useBot } from '../../../hooks/index';

const ModalButton = styled(Button)`
  border: none;
`;

ModalButton.defaultProps = {
  icon: 'arrowsExpand',
  colour: 'black'
};

const IframeModalButton = ({ content, ...props }) => {
  const { setIframeModal, onIframeModalOpen } = useBot();
  const { t } = useTranslation(['bots', 'converse']);

  return (
    <ModalButton
      {...props}
      aria-label={t('open_widget_button_label', { ns: 'converse' })}
      onClick={() => {
        setIframeModal({
          type: 'content',
          value: content
        });
        onIframeModalOpen('open');
      }}
    />
  );
};

IframeModalButton.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string
  })
};

export default IframeModalButton;
