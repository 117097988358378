import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AnimatePresence, motion } from '@ubisend/framer-motion';
import { Markdown } from '@ubisend/pulse-markdown';
import {
  breakpoints,
  Button as CoreButton,
  Placeholder
} from '@ubisend/pulse-components';

import Button from '../Button';
import { useBelowScreenSize } from '../../../../hooks/index';

const Heading = styled.h1`
  ${props => `font-family: '${props.theme.bot.headerFont}', sans-serif;`}
  ${tw`mb-8 text-3xl`}
`;

const SmallScreenOverlay = styled.div`
  ${tw`absolute flex xl:w-full w-full h-full xl:hidden flex-grow pin-l pin-t items-center justify-center`}
  background: rgba(0,0,0,0.8);
`;

const Lightbox = styled(motion.div)`
  ${tw`fixed w-full h-screen pin-l pin-t z-20 bg-white `}
`;

Lightbox.defaultProps = {
  layout: true,
  initial: { overflow: 'auto' },
  animate: { overflow: 'auto' },
  exit: { overflow: 'hidden' },
  transition: { ease: [0.76, 0, 0.24, 1], duration: 0.4 }
};

const Expand = props => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z"
      clipRule="evenodd"
    />
  </svg>
);

const StyledExpand = styled(Expand)`
  ${tw`mr-1`}
`;

const Container = styled(motion.div)`
  ${tw`relative h-full p-10 overflow-y-auto`}
`;

const TopButton = styled(CoreButton)`
  ${tw`absolute`}
  top: 1.1rem;
  ${props => (props.right ? 'right: 1.1rem;' : 'left: 1.1rem;')}
`;

TopButton.defaultProps = {
  variant: 'inline',
  icon: 'arrowsExpand',
  colour: 'black'
};

const Content = ({
  content: contentObject,
  isLinkedFromAnotherSection = false,
  backArrowCallback,
  isLoading = false
}) => {
  const { content } = contentObject;

  const [showLightbox, setShowLightbox] = useState(false);

  const smallScreen = useBelowScreenSize(breakpoints.xl);

  const { t } = useTranslation('full_page');

  useEffect(() => {
    //TODO: if on regular screen and light box is open and coming from another section, remain open
    if (isLinkedFromAnotherSection && smallScreen && showLightbox === false) {
      setShowLightbox(true);
    }
  }, [isLinkedFromAnotherSection, smallScreen, showLightbox]);

  return (
    <>
      {isLoading && (
        <Container layout layoutId="loading">
          <Placeholder />
        </Container>
      )}
      {!isLoading && (
        <>
          <Container
            layout
            layoutId="content"
            data-testid="full-page-content-message">
            {!smallScreen && (
              <>
                {isLinkedFromAnotherSection && (
                  <TopButton icon="arrowNarrowLeft" onClick={backArrowCallback}>
                    {t('back_content_button')}
                  </TopButton>
                )}
                <TopButton right onClick={() => setShowLightbox(true)} />
              </>
            )}
            <Heading>{content.title}</Heading>
            <Markdown>{content.markdown}</Markdown>
          </Container>
          <SmallScreenOverlay>
            <Button
              onClick={() => setShowLightbox(true)}
              style={{ zIndex: 11 }}>
              <StyledExpand />
            </Button>
          </SmallScreenOverlay>
          <AnimatePresence exitBeforeEnter>
            {showLightbox && (
              <Lightbox layoutId="content lightbox">
                <Container layout layoutId="content lightbox container">
                  <TopButton
                    right
                    onClick={() => setShowLightbox(false)}
                    style={{ zIndex: 30 }}
                  />
                  {isLinkedFromAnotherSection && (
                    <TopButton
                      icon="arrowNarrowLeft"
                      onClick={backArrowCallback}>
                      {t('back_content_button')}
                    </TopButton>
                  )}
                  <Heading>{content.title}</Heading>
                  <Markdown>{content.markdown}</Markdown>
                </Container>
              </Lightbox>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

Content.propTypes = {
  isLoading: PropTypes.bool,
  isLinkedFromAnotherSection: PropTypes.bool,
  backArrowCallback: PropTypes.func,
  content: PropTypes.shape({
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      markdown: PropTypes.string.isRequired
    })
  }).isRequired
};

Content.Container = Container;
Content.SmallScreenOverlay = SmallScreenOverlay;
Content.Expand = StyledExpand;
Content.Lightbox = Lightbox;
Content.TopButton = TopButton;

export default Content;
