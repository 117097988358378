import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.span`
  ${tw`underline cursor`}
  cursor: pointer;
`;

const SectionLink = ({ children, href, ...props }) => {
  const history = useHistory();

  const id = href.slice(href.lastIndexOf('/') + 1);

  const handleClick = () => {
    if (id) {
      history.push(`/window/section/${id}`);
    }
  };

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  );
};

SectionLink.propTypes = {
  href: PropTypes.string.isRequired
};

export default SectionLink;
