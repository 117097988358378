import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';

import { UpdateWorkplaceLink, CreateWorkplaceLink } from '../Components/index';
import { acceptPolicy as acceptPolicyApi } from '../api/settings';

const LinkFacebookWorkplace = () => {
  const { isLoading, isSuccess, data } = useQuery('workplace/settings');

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('workplace/settings');
    }
  });

  const hasSettings = Boolean(data?.data.auth_token);

  const PolicyCheck = () => {
    if (!data.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://www.facebook.com/legal/WorkplacePlatformPolicy"
        />
      );
    }

    if (!hasSettings) {
      return <CreateWorkplaceLink />;
    }

    return <UpdateWorkplaceLink />;
  };

  return (
    <PageWrapper center header="Facebook Workplace">
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} center />
            </Panel>
          )}
          {isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default LinkFacebookWorkplace;
