import React from 'react';

import { Flex, Panel, WizardProgress } from '@ubisend/pulse-components';

import WhatsAppLogin from './WhatsAppLogin';
import SelectWaba from './SelectWaba';
import SelectPhoneNumber from './SelectPhoneNumber';
import { useWhatsAppSettings } from '../hooks/index';

const SetupWhatsAppComponent = () => {
  const { settings } = useWhatsAppSettings();

const getCurrent = () => {
  if (settings.accessToken) {
    return 1;
  }

  if (settings.wabaId) {
    return 2;
  }

    return 0;
}

  return (
    <Panel
      divider
      header="Link your WhatsApp"
      actions={<WizardProgress steps={3} current={getCurrent()} />}>
      <Flex col ySpace mt>
        {!settings.accessToken && <WhatsAppLogin />}
        {settings.accessToken && !settings.wabaId && <SelectWaba />}
        {settings.accessToken && settings.wabaId && <SelectPhoneNumber />}
      </Flex>
    </Panel>
  );
};

export default SetupWhatsAppComponent;
