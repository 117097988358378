import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Panel,
  FormGroup,
  TextInput,
  Label,
  Button,
  useNotification
} from '@ubisend/pulse-components';
import { WysiwygEditor } from '@ubisend/pulse-edit';

import { createNewSection } from '../api/index';
import { useImports } from '../hooks/index';

const CreateSection = () => {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');

  const { showSuccess } = useNotification();
  const { item, section, dispatch } = useImports();

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const { mutate, data, isSuccess } = useMutation(createNewSection, {
    onSuccess: ({ data }) => {
      dispatch({
        type: 'ADD_SECTION',
        import: item,
        section,
        newSection: data.data
      });

      showSuccess('Successfully created new section');
    }
  });

  const handleContentChange = useCallback(content => {
    setContent(content);
  }, []);

  const handleSubmit = () => mutate({ id: item.id, name, content });

  return (
    <Flex>
      {isSuccess && (
        <Redirect
          to={`/knowledge-bases/${item.id}/sections/${data.data.data.id}/content`}
        />
      )}
      <Panel>
        <FormGroup>
          <Label htmlFor="name">Section name</Label>
          <TextInput
            id="name"
            value={name}
            onChange={handleNameChange}
            placeholder="A new section"
          />
        </FormGroup>
        <FormGroup>
          <WysiwygEditor
            value={content}
            onChange={handleContentChange}
            aria-label="Section content"
          />
        </FormGroup>
        <Flex right>
          <Button onClick={handleSubmit} icon="save">
            Save
          </Button>
        </Flex>
      </Panel>
    </Flex>
  );
};

export default CreateSection;
