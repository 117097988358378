import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import { Flex, Panel, Tooltip } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const IconWrapper = styled(motion.button)`
  ${tw`bg-transparent border-0 p-0 outline-none flex items-center justify-center cursor-pointer`}
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const ShowWalkThroughButton = props => (
  <Flex
    style={{
      right: '1rem',
      bottom: '1rem',
      zIndex: 20,
      position: 'absolute'
    }}>
    <Tooltip
      position={Tooltip.POSITIONS.LEFT}
      tooltip={<Tooltip.Content>New Features</Tooltip.Content>}>
      <Panel {...props}>
        <IconWrapper aria-label="Show new features">
          <Icon type="gift" outline />
        </IconWrapper>
      </Panel>
    </Tooltip>
  </Flex>
);

export default ShowWalkThroughButton;
