import authRoutes from '@ubisend/pulse-auth';
import metricsRoutes from '@ubisend/pulse-metrics';
import complianceRoutes from '@ubisend/pulse-compliance';
import faqRoutes from '@ubisend/pulse-faqs';
import trainingRoutes from '@ubisend/pulse-training';
import fallbackRoutes from '@ubisend/pulse-fallback';
import demoRoutes from '@ubisend/pulse-demo';
import smalltalkRoutes from '@ubisend/pulse-smalltalk';
import convoRoutes from '@ubisend/pulse-convo';
import botRoutes from '@ubisend/pulse-bot';
import broadcastRoutes from '@ubisend/pulse-broadcast';
import templateRoutes from '@ubisend/pulse-templates';
import blockRoutes from '@ubisend/pulse-block';
import clientRoutes from '@ubisend/pulse-clients';
import chatRoutes from '@ubisend/pulse-chat';
import feedbackRoutes from '@ubisend/pulse-feedback';
import integrationsRoutes from '@ubisend/pulse-integrations';
import nlpRoutes from '@ubisend/pulse-nlp';
import paymentRoutes from '@ubisend/pulse-payments';
import channelRoutes from '@ubisend/pulse-channels';
import docsRoutes from '@ubisend/pulse-docs';
import facebookRoutes from '@ubisend/pulse-facebook';
import workplaceRoutes from '@ubisend/pulse-facebook-workplace';
import teamRoutes from '@ubisend/pulse-teams';
import importRoutes from '@ubisend/pulse-import';
import composerRoutes from '@ubisend/pulse-custom-composers';
import ticketRoutes from '@ubisend/pulse-tickets';
import groupRoutes from '@ubisend/pulse-groups';
import logRoutes from '@ubisend/pulse-logs';
import telegramRoutes from '@ubisend/pulse-telegram';
import builderRoutes from '@ubisend/pulse-builder';
import instagramRoutes from '@ubisend/pulse-instagram';
import linkRoutes from '@ubisend/pulse-links';
import fileRoutes from '@ubisend/pulse-files';
import alexaRoutes from '@ubisend/pulse-alexa';
import voipRoutes from '@ubisend/pulse-voip';
import whatsAppRoutes from '@ubisend/pulse-whatsapp';

const pluginRoutes = [
  ...metricsRoutes,
  ...authRoutes,
  ...complianceRoutes,
  ...faqRoutes,
  ...trainingRoutes,
  ...fallbackRoutes,
  ...demoRoutes,
  ...smalltalkRoutes,
  ...convoRoutes,
  ...botRoutes,
  ...broadcastRoutes,
  ...templateRoutes,
  ...blockRoutes,
  ...clientRoutes,
  ...chatRoutes,
  ...feedbackRoutes,
  ...integrationsRoutes,
  ...nlpRoutes,
  ...paymentRoutes,
  ...channelRoutes,
  ...docsRoutes,
  ...facebookRoutes,
  ...workplaceRoutes,
  ...teamRoutes,
  ...importRoutes,
  ...composerRoutes,
  ...ticketRoutes,
  ...groupRoutes,
  ...logRoutes,
  ...telegramRoutes,
  ...builderRoutes,
  ...instagramRoutes,
  ...linkRoutes,
  ...fileRoutes,
  ...alexaRoutes,
  ...voipRoutes,
  ...whatsAppRoutes
];

export default pluginRoutes;
