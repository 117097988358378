import React from 'react';

import {
  Panel,
  InfoSection,
  Flex,
  OneQuarterLayout,
  Checkbox
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';

const SettingPageSettings = () => {
  const { settings, focusSettings, setSettings } = useBotSettings();

  const handleResetChange = () => {
    setSettings(settings => ({
      ...settings,
      reset_enabled: !settings.reset_enabled
    }));

    focusSettings();
  };

  const handleLanguageChange = () => {
    setSettings(settings => ({
      ...settings,
      change_subscriber_language_enabled: !settings.change_subscriber_language_enabled
    }));

    focusSettings();
  };

  const handleTranscriptChange = () => {
    setSettings(settings => ({
      ...settings,
      export_transcript_enabled: !settings.export_transcript_enabled
    }));

    focusSettings();
  };

  const handleDataExportChange = () => {
    setSettings(settings => ({
      ...settings,
      export_subscriber_enabled: !settings.export_subscriber_enabled
    }));

    focusSettings();
  };

  const handleDeleteChange = () => {
    setSettings(settings => ({
      ...settings,
      delete_subscriber_enabled: !settings.delete_subscriber_enabled
    }));

    focusSettings();
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Settings"
          info="Choose the options to display inside your chatbot's settings panel."
        />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <Checkbox
            checked={settings.change_subscriber_language_enabled}
            onChange={handleLanguageChange}
            label="Language"
          />
          <Checkbox
            checked={settings.reset_enabled}
            onChange={handleResetChange}
            label="Reset"
          />
          <Checkbox
            checked={settings.export_transcript_enabled}
            onChange={handleTranscriptChange}
            label="Transcript"
          />
          <Checkbox
            checked={settings.export_subscriber_enabled}
            onChange={handleDataExportChange}
            label="Data export"
          />
          <Checkbox
            checked={settings.delete_subscriber_enabled}
            onChange={handleDeleteChange}
            label="Delete"
          />
        </Flex>
      </Panel>
    </Flex>
  );
};

export default SettingPageSettings;
