import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const Slider = styled(motion.div)`
  ${tw`fixed pin-t`}
  z-index: ${props => (props.from === 'right' ? 70 : 30)};
  width: ${({ width = '80vw' }) => width};
  ${props => (props.from === 'right' ? tw`pin-r` : tw`pin-l`)}
`;

const inTransition = { duration: 0.6, ease: [0.16, 1, 0.3, 1] };
const outTransition = { duration: 0.4, ease: [0.16, 1, 0.3, 1] };

const right = {
  visible: { x: '0', transition: inTransition },
  hidden: { x: '100%', transition: outTransition }
};

const left = {
  visible: {
    x: '12rem',
    transition: inTransition
  },
  hidden: {
    x: '-12rem',
    transition: outTransition
  }
};

const AnimatedSlider = ({ children, from, ...rest }) => (
  <Slider
    initial="hidden"
    animate="visible"
    variants={from === 'right' ? right : left}
    exit="hidden"
    from={from}
    {...rest}>
    {children}
  </Slider>
);

AnimatedSlider.propTypes = {
  from: PropTypes.oneOf(['left', 'right'])
};

export default AnimatedSlider;
export { right, left };
