import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Flex,
  Button,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  useNotification,
  Pagination,
  Label,
  NoResults,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer,
  FilterMenu,
  useFilterReducer
} from '@ubisend/pulse-components';
import {
  SubscriberLink,
  TicketCreatedAt,
  TicketNameTableCell
} from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { deleteLog } from '../api/index';
import { LogName, LogLevelSelect, LogTypeSelect } from '../Components/index';

const Logs = () => {
  const order = useOrderableTableReducer({ id: 'logs' });
  const pagination = usePaginationReducer({ id: 'logs' });
  const filters = useFilterReducer({
    id: 'logs',
    initialFilters: { levels: [], types: [] },
    options: { pagination }
  });

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();

  const query = useQuery(
    ['logs', { ...pagination.params, ...order.params, ...filters.form }],
    // Don't cache logs as they are constantly changing
    { cacheTime: 0 }
  );

  const { mutate } = useMutation(deleteLog, {
    onSuccess: () => {
      showSuccess('Successfully deleted log');
      queryClient.invalidateQueries('logs');
    }
  });

  const handleDelete = log => () => {
    mutate(log.id);
  };

  const handleTypeChange = options => {
    filters.updateFilters({
      types: options ? options.map(option => option.value) : []
    });
  };

  const handleLevelChange = options => {
    filters.updateFilters({
      levels: options ? options.map(option => option.value) : []
    });
  };

  return (
    <PageWrapper
      header="Logs"
      subheader="View logs"
      actions={
        <FilterMenu
          position={FilterMenu.POSITIONS.LEFT}
          buttonProps={{ loading: query.isLoading }}
          {...filters.props}>
          <Flex fat between col ySpace style={{ width: '20rem' }}>
            <Flex col fat>
              <Label htmlFor="types">Types</Label>
              <LogTypeSelect
                id="types"
                value={filters.filters.types}
                onChange={handleTypeChange}
              />
            </Flex>
            <Flex col fat>
              <Label htmlFor="levels">Levels</Label>
              <LogLevelSelect
                id="levels"
                value={filters.filters.levels}
                onChange={handleLevelChange}
              />
            </Flex>
          </Flex>
        </FilterMenu>
      }>
      <>
        {query.showNoResultsMessage && (
          <StretchPanel>
            <NoResults text="No logs found" />
          </StretchPanel>
        )}
        {query.showTable && (
          <StretchPanel>
            <Table loading={query.isLoading} loadingColumns={4}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Name', sort: 'name' },
                    { label: 'Subscriber', sort: null },
                    { label: 'Date', sort: 'created_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((log, key) => (
                    <TableRow key={key}>
                      <TicketNameTableCell>
                        <LogName {...log} />
                      </TicketNameTableCell>
                      <TableCell>
                        <SubscriberLink subscriber={log.subscriber} />
                      </TableCell>
                      <TableCell>
                        <TicketCreatedAt date={log.created_at} />
                      </TableCell>
                      <TableActions>
                        <Button
                          as={Link}
                          variant="secondary"
                          icon="eye"
                          to={`/logs/${log.id}`}>
                          View
                        </Button>
                        <PermissionFilter can="delete logs">
                          <Button
                            variant="secondary"
                            colour="danger"
                            icon="trash"
                            onClick={handleDelete(log)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </StretchPanel>
        )}
      </>
    </PageWrapper>
  );
};

export default Logs;
