import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';

import { acceptPolicy as acceptPolicyApi } from '../api/settings';
import { useFacebookSettings } from '../hooks';
import FacebookLink from './FacebookLink';

const FacebookLanding = () => {
  const { settings, isLoading } = useFacebookSettings();

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('facebook/settings');
    }
  });

  const PolicyCheck = () => {
    if (!settings.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://developers.facebook.com/devpolicy/"
        />
      );
    }

    return <FacebookLink data-testid="facebook-settings" />;
  };

  return (
    <PageWrapper center header="Facebook Messenger">
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} />
            </Panel>
          )}
          {!isLoading && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default FacebookLanding;
