import React from 'react';

import {
  ColourPicker,
  Divider,
  Panel,
  Label,
  Flex,
  OneQuarterLayout,
  InfoSection,
  Checkbox,
  Accordion
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';
import CallToActions from './CallToActions';
import ColourContrast from '../ColourContrast';

const CallToActionSettings = () => {
  const {
    settings,
    setSettings,
    focusLauncher,
    handleColourChange
  } = useBotSettings();

  const handleCallToActionChange = () => {
    focusLauncher();
    setSettings(settings => ({
      ...settings,
      call_to_action_enabled: !settings.call_to_action_enabled
    }));
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Call-to-actions"
          info="Customise the colour and visibility of call-to-actions."
        />
      </OneQuarterLayout>
      <Panel>
        <Checkbox
          checked={settings.call_to_action_enabled}
          onChange={handleCallToActionChange}
          label="Show call-to-action"
        />
        <Flex mt mb col>
          <Flex mbSm between middle>
            <Label mb={false}>Call-to-action background colour</Label>
            <ColourContrast
              backgrounds={[settings.styles.ctaBackgroundColour]}
              foreground={'white'}
            />
          </Flex>
          <ColourPicker
            onChange={focusLauncher}
            color={settings.styles.ctaBackgroundColour}
            onChangeComplete={handleColourChange('ctaBackgroundColour')}
          />
        </Flex>
        <Divider mtNone fullWidth />
        <Accordion header="Configured call-to-actions" inner>
          <CallToActions />
        </Accordion>
      </Panel>
    </Flex>
  );
};

export default CallToActionSettings;
