import CarouselInput from './CarouselInput';
import { valid as validButton, setButton } from '../../../Buttons/Types/index';

const carousel = {
  id: 'carousel',
  name: 'Carousel',
  Input: CarouselInput,
  setInitialContent: content => {
    const items = content.items || [
      {
        title: '',
        subtitle: '',
        image: 'http://placehold.it/400x200',
        image_description: '',
        url: '',
        full_size: false,
        responses: []
      }
    ];

    return {
      items: items.map(item => {
        const responses = item.responses || [];

        return {
          title: item.title || '',
          subtitle: item.subtitle || '',
          image: item.image || 'http://placehold.it/400x200',
          image_description: item.image_description || '',
          url: item.url || '',
          full_size: item.full_size || false,
          responses: responses.map(setButton)
        };
      })
    };
  },
  valid: content => {
    return (
      content.items.length > 0 &&
      content.items.every(content => {
        return (
          content.title.length > 0 &&
          content.subtitle.length > 0 &&
          content.image.length > 0 &&
          content.url.length >= 0 &&
          content.responses.length > 0 &&
          content.responses.every(validButton)
        );
      })
    );
  },
  snippet: `{
    "type": "carousel",
    "content": {
      "items": [
        {
          "title": "",
          "subtitle": "",
          "image": "http://placehold.it/400x200",
          "image_description": "",
          "full_size": false,
          "url": "",
          "responses": [
            {"type": "postback", "label": "", "action": ""}
          ]
        }
      ]
    }
}`
};

export default carousel;
