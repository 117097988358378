import React from 'react';
import styled from 'styled-components';

import { Span } from '@ubisend/pulse-components';

const Image = styled.img`
  ${tw`h-4`};
  width: 3.5rem;
  margin-left: 0.3rem;
  margin-bottom: 0.1rem;
`;

const Link = styled.a`
  ${tw`flex items-center text-black no-underline `};
`;

const logo =
  'https://s3.eu-west-2.amazonaws.com/ubisend.website/assets/static/images/logo.svg';

const PoweredBy = () => {
  return (
    <Link
      rel="nofollow noopener noreferrer"
      target="_blank"
      href="https://www.ubisend.com/powered-by-ubisend">
      {/* eslint-disable i18next/no-literal-string */}
      <Span>powered by</Span>
      <Image src={logo} alt="ubisend logo" />
      {/* eslint-enable i18next/no-literal-string */}
    </Link>
  );
};

export default PoweredBy;
export { logo };
