import React from 'react';
import styled from 'styled-components';

import { useTheme } from '../../../hooks/index';

const StyledWindow = styled.div`
  ${tw`w-full h-full pin-t absolute text-white overflow-x-hidden`}

  *:not(h1) {
    ${props => `font-family: '${props.theme.bodyFont}', sans-serif;`}
  }
`;

const Window = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <StyledWindow data-testid="embed-window" theme={theme} {...props}>
      {children}
    </StyledWindow>
  );
};

export default Window;
