import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import visualDiff from 'node-htmldiff';

import {
  Flex,
  Panel,
  OneHalfLayout,
  useTheme
} from '@ubisend/pulse-components';
import { Markdown } from '@ubisend/pulse-markdown';

const Container = styled(Panel)`
  ${tw`whitespace-pre-wrap`}
`;

const DiffViewer = styled(Markdown)`
  ins {
    opacity: 80%;
    color: white;
    text-decoration: none;
    background-color: ${props => props.insertion};
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
  }

  ins > img {
    border: 2.5px solid ${props => props.insertion};
  }

  del {
    opacity: 80%;
    color: white;
    background-color: ${props => props.deletion};
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
  }
  del > img {
    opacity: 50%;
    border: 2.5px solid ${props => props.deletion};
  }
`;

const ContentDiff = ({ before, after }) => {
  const { danger, positive } = useTheme();

  const diff = visualDiff(before, after);

  return (
    <Flex between xSpace>
      <OneHalfLayout>
        <Container header="Changes">
          <DiffViewer deletion={danger} insertion={positive}>
            {diff}
          </DiffViewer>
        </Container>
      </OneHalfLayout>
      <OneHalfLayout>
        <Container header="Preview">
          <Markdown>{after}</Markdown>
        </Container>
      </OneHalfLayout>
    </Flex>
  );
};

ContentDiff.propTypes = {
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired
};

export default ContentDiff;
