import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import {
  Messages,
  Notifications,
  Tickets,
  History,
  Settings
} from './Screens/index';
import { RouteChanger, AuthChanger } from '../../Components/index';
import {
  WindowHeader,
  Background,
  Window,
  CustomCss
} from './Components/index';
import { GroupProvider } from './Providers/index';

const GroupContainer = styled.div`
  ${tw`flex justify-between h-full flex-col-reverse flex-grow lg:flex-row`}
`;

const MessageContainer = styled.div`
  ${tw`w-full h-full`}
  & > div:first-child {
    ${tw`px-4`}
  }
  box-sizing: border-box;
`;
const FullPageWrapper = ({ children }) => (
  <>
    <GroupProvider>
      <GroupContainer>
        <MessageContainer id="fullpage-custom-bot">
          <WindowHeader />
          {children}
        </MessageContainer>
        <div id="content-message-slide-in" />
      </GroupContainer>
    </GroupProvider>
  </>
);
const FullPage = ({ start = '/window/body/messages', path = null }) => {
  return (
    <MemoryRouter initialEntries={[start]}>
      <RouteChanger path={path} />
      <AuthChanger />
      <Background />
      <CustomCss />
      <Window>
        <Switch>
          <Route path="/window">
            <FullPageWrapper>
              <Route exact path="/window/section/:id" component={Messages} />
              <Route path="/window/body">
                <Route path="/window/body/messages" component={Messages} />
                <Route
                  exact
                  path="/window/body/notifications"
                  component={Notifications}
                />
                <Route exact path="/window/body/tickets" component={Tickets} />
                <Route
                  exact
                  path="/window/body/settings"
                  component={Settings}
                />
                <Route exact path="/window/body/history" component={History} />
              </Route>
            </FullPageWrapper>
          </Route>
        </Switch>
      </Window>
    </MemoryRouter>
  );
};

FullPage.propTypes = {
  start: PropTypes.string,
  path: PropTypes.string
};

export default FullPage;
