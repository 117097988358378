import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  TableActions,
  Button,
  Pagination,
  NoResults,
  useModal,
  useNotification,
  Flex,
  Tooltip,
  usePaginationReducer,
  OrderableTableHeadCell,
  useOrderableTableReducer,
  FilterMenu,
  useFilterReducer,
  Label
} from '@ubisend/pulse-components';
import {
  LocationsConsumer,
  useLanguages,
  useQuery,
  useQueryClient,
  useMutation
} from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { GroupSelect } from '@ubisend/pulse-groups';

import {
  ExportFaqs,
  EditFaq,
  FaqSelect,
  LocationSelect
} from '../Components/index';
import { deleteFaq } from '../api/index';

const defaultFilters = {
  groupIds: [],
  locations: [],
  faq_id: null
};

const Faqs = () => {
  const pagination = usePaginationReducer({ id: 'faqs' });
  const order = useOrderableTableReducer({ id: 'faqs' });
  const filters = useFilterReducer({
    id: 'faqs',
    initialFilters: defaultFilters,
    options: { pagination }
  });

  const [showEdit, setShowEdit] = useState(false);

  const { showModal, hideModal } = useModal();
  const { showNotification } = useNotification();
  const { defaultLanguage } = useLanguages();

  const queryClient = useQueryClient();

  const params = {
    ...(!filters.form.faq_id && { ...pagination.params, ...order.params }),
    ...filters.form
  };

  const query = useQuery(['faqs', params]);
  const mutation = useMutation(deleteFaq, {
    onSuccess: () => {
      showNotification({ message: `FAQ was succesfully deleted.` });
      queryClient.invalidateQueries(['faqs', params]);
    }
  });

  const handleFaqDelete = ({ id, name }) => {
    showModal({
      header: 'Delete FAQ',
      body: `Are you sure you want to delete "${name}"?`,
      handleConfirm: () => {
        mutation.mutate(id);
        hideModal();
      }
    });
  };

  const handleGroupSelect = items => {
    filters.updateFilters({
      groupIds: items ? items.map(group => group.value) : []
    });
  };

  const handleFaqSelect = item => {
    filters.updateFilters({
      faq_id: item ? item.value : null
    });
  };

  const handleLocationSelect = items => {
    filters.updateFilters({
      locations: items ? items.map(item => item.value) : []
    });
  };

  return (
    <PageWrapper
      header="FAQs"
      subheader="View and manage FAQs"
      actions={
        <Flex xSpace>
          <FilterMenu
            buttonProps={{ loading: query.isLoading }}
            {...filters.props}>
            <Flex col style={{ width: '20rem' }}>
              <Flex fat col mb>
                <Label id="categories">FAQ</Label>
                <FaqSelect
                  value={filters.filters.faq_id}
                  onChange={handleFaqSelect}
                  selectedGroups={filters.filters.groupIds}
                  selectedLocations={filters.filters.locations}
                />
              </Flex>
              <LocationsConsumer>
                <Flex fat col mb>
                  <Label id="locations">Locations</Label>
                  <LocationSelect
                    value={filters.filters.locations}
                    onChange={handleLocationSelect}
                    isMulti
                  />
                </Flex>
              </LocationsConsumer>
              <Flex fat col>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  value={filters.filters.groupIds}
                  onChange={handleGroupSelect}
                  for="faqs"
                  sortAlphabetically
                />
              </Flex>
            </Flex>
          </FilterMenu>
          <PermissionFilter can="create faqs">
            <Button variant="secondary" as={Link} to={`/faqs/create`}>
              + FAQ
            </Button>
          </PermissionFilter>
          <ExportFaqs />
          <PermissionFilter can="edit faq settings">
            <Button
              as={Link}
              variant="secondary"
              icon="cog"
              to={'/faqs/follow-up'}>
              Follow up
            </Button>
          </PermissionFilter>
        </Flex>
      }>
      {showEdit && (
        <EditFaq handleCancel={() => setShowEdit(false)} faq={showEdit} />
      )}
      <Panel mt>
        {query.showNoResultsMessage && <NoResults />}
        {query.showTable && (
          <Table loading={query.isLoading} loadingColumns={5}>
            <TableHead>
              <TableRow>
                <OrderableTableHeadCell
                  row={{ label: 'Name', sort: 'name' }}
                  {...order.props}
                />
                <TableHeadCell>Example Question</TableHeadCell>
                <LocationsConsumer>
                  <TableHeadCell>Location</TableHeadCell>
                </LocationsConsumer>
                <TableHeadCell>Tags</TableHeadCell>
                <OrderableTableHeadCell
                  row={{ label: 'Number of answers', sort: 'response_count' }}
                  {...order.props}
                />
                <TableHeadCell />
              </TableRow>
            </TableHead>
            {query.isSuccess && (
              <TableBody>
                {query.data.data.map((faq, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Flex center>
                        <span>{faq.name}</span>
                      </Flex>
                    </TableCell>
                    <TableCell>
                      {
                        faq.intents.find(intent => {
                          return intent.language.id === defaultLanguage.id;
                        }).utterances[0].utterance
                      }
                    </TableCell>
                    <LocationsConsumer>
                      <TableCell>
                        {faq.location ? faq.location.name : 'No Location'}
                      </TableCell>
                    </LocationsConsumer>
                    <TableCell>
                      {faq.groups.length > 0
                        ? faq.groups.map(({ name }) => name).join(', ')
                        : 'No Tags'}
                    </TableCell>
                    <TableCell>
                      <Flex center>
                        {faq.response_count}
                        {faq.response_count === 0 && (
                          <TableCell>
                            <Tooltip
                              tooltip={
                                <Tooltip.Content>
                                  This FAQ has no answers. It must have at least
                                  one answer to be used within the bot.
                                </Tooltip.Content>
                              }>
                              <Tooltip.Icon>!</Tooltip.Icon>
                            </Tooltip>
                          </TableCell>
                        )}
                      </Flex>
                    </TableCell>
                    <TableActions>
                      <PermissionFilter can="edit faqs">
                        <Button
                          variant="secondary"
                          onClick={() => setShowEdit(faq)}
                          icon="pencilAlt">
                          Edit
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="edit faqs">
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/faqs/${faq.id}/questions`}>
                          Questions
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="edit faqs">
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/faqs/${faq.id}/responses`}>
                          Answers
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="delete faqs">
                        <Button
                          variant="secondary"
                          colour="danger"
                          icon="trash"
                          onClick={() => handleFaqDelete(faq)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )}
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </Panel>
    </PageWrapper>
  );
};

export default Faqs;
export { defaultFilters };
