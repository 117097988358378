import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Button,
  Divider,
  useNotification
} from '@ubisend/pulse-components';
import {
  useConditionalReducer,
  Conditionals,
  AddConditionalButton,
  ConditionalContext,
  Match
} from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals } from '../../../../../../subjects/index';
import { useBuilder } from '../../../../hooks/index';
import { createStepTrigger } from '../../../../api/index';
import StepPreview from '../MessageStep/StepPreview';

const AddTriggerNode = ({ handleClose, node }) => {
  const { dispatch } = useBuilder();
  const conditional = useConditionalReducer({
    subjects: conditionals
  });
  const { showSuccess } = useNotification();

  const { isLoading, mutate } = useMutation(createStepTrigger, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added trigger');
      dispatch({
        type: 'ADD_TRIGGER',
        stepId: node.id,
        triggerDetails: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    if (conditional.valid && !isLoading) {
      mutate({
        stepId: node.id,
        trigger: {
          description: `Trigger for step #${node.id}`,
          ...conditional.form,
          x: node.x + 300,
          y: node.y - 110,
          variables: [],
          metrics: []
        }
      });
    }
  };

  const destinationNode = {
    id: node.id,
    blocks: [],
    base: node.base
  };

  return (
    <Modal
      header="Add trigger"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex>
        <Flex pad fat col borderRight>
          <Flex as="form" col tall onSubmit={handleSubmit}>
            <ConditionalContext.Provider value={conditional}>
              <Match />
              <Divider />
              <Conditionals labelPrefix="trigger-conditionals" />
              <Flex right>
                <AddConditionalButton />
              </Flex>
              <Divider />
              <Flex center xSpace bottom>
                <Flex xSpace fat right bottom>
                  <Button
                    icon="save"
                    disabled={!conditional.valid || isLoading}
                    type="submit">
                    Save
                  </Button>
                </Flex>
              </Flex>
            </ConditionalContext.Provider>
          </Flex>
        </Flex>
        <Flex pad ySpace col center style={{ background: '#f3f3f4' }}>
          <StepPreview node={destinationNode} />
        </Flex>
      </Flex>
    </Modal>
  );
};

AddTriggerNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.object.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
};

export default AddTriggerNode;
