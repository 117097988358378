import React, { useState } from 'react';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  useNotification,
  PageWrapper,
  Panel,
  TextInput,
  Button,
  OneHalfLayout,
  Explainer,
  Flex,
  Label,
  Select,
  Span
} from '@ubisend/pulse-components';

import { SubscriberRetentionTypeSelect } from '../Components/index';
import { updateCompliance } from '../api/index';

const MIN_DAYS = 10;
const MAX_DAYS = 600;

const Compliance = () => {
  const [compliance, setCompliance] = useState();

  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  useQuery('compliance', {
    onSuccess: ({ data }) => {
      setCompliance({
        subscriber_retention_type_id: data.subscriber_retention_type.id,
        subscriber_retention: data.subscriber_retention,
        log_retention: data.log_retention
      });
    }
  });

  const mutation = useMutation(updateCompliance, {
    onSuccess: () => {
      queryClient.invalidateQueries('compliance');
      showSuccess('Data compliance records updated.');
    }
  });

  const handleComplianceSubmit = () => {
    mutation.mutate(compliance);
  };

  const handleSubscriberRetentionChange = event => {
    const value = event.target.value;
    setCompliance(compliance => ({
      ...compliance,
      subscriber_retention: value
    }));
  };

  const handleSubscriberRetentionTypeChange = ({ value }) => {
    setCompliance(compliance => ({
      ...compliance,
      subscriber_retention_type_id: value
    }));
  };

  const handleLogRetentionChange = event => {
    const value = event.target.value;
    setCompliance(compliance => ({
      ...compliance,
      log_retention: value
    }));
  };

  return (
    <PageWrapper
      header="Data Compliance"
      subheader="Manage your chatbot's data retention">
      <OneHalfLayout>
        <Panel header="Retention Period" divider loading={!compliance}>
          {compliance && (
            <Flex ySpace col>
              <Explainer mb>
                Adjust the number of days the platform retains different types
                of data before deleting it.
              </Explainer>
              <Label>Subscribers</Label>
              <Flex fat center xSpace>
                <Span noWrap>Delete</Span>
                <TextInput
                  type="number"
                  min={MIN_DAYS}
                  max={MAX_DAYS}
                  aria-label="subscriber-retention"
                  style={{ width: '10rem' }}
                  value={compliance.subscriber_retention}
                  onChange={handleSubscriberRetentionChange}
                />
                <Span noWrap>days after</Span>
                <Flex fat growChildren>
                  <SubscriberRetentionTypeSelect
                    aria-label="retention-type"
                    value={compliance.subscriber_retention_type_id}
                    onChange={handleSubscriberRetentionTypeChange}
                  />
                </Flex>
              </Flex>
              <Label>Conversation Logs</Label>
              <Flex fat center xSpace>
                <Span noWrap>Delete</Span>
                <TextInput
                  type="number"
                  min={MIN_DAYS}
                  max={MAX_DAYS}
                  aria-label="log-retention"
                  style={{ width: '10rem' }}
                  value={compliance.log_retention}
                  onChange={handleLogRetentionChange}
                />
                <Span noWrap>days after</Span>
                <Flex fat growChildren>
                  <Select
                    isDisabled
                    options={[{ value: null, label: 'creation' }]}
                    value={{ value: null, label: 'creation' }}
                  />
                </Flex>
              </Flex>
              <Flex right>
                <Button onClick={handleComplianceSubmit} icon="save">
                  Save
                </Button>
              </Flex>
            </Flex>
          )}
        </Panel>
      </OneHalfLayout>
    </PageWrapper>
  );
};

export default Compliance;
