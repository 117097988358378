import React from 'react';

import { Button, Flex } from '@ubisend/pulse-components';

import { useButtons } from '../../hooks/index';

const AddButton = () => {
  const { handleButton, types } = useButtons();

  const handleClick = type => {
    handleButton({
      type: type.id,
      ...type.setInitialContent({})
    });
  };

  return (
    <Flex xSpaceSm right wrap gap>
      {types.map(type => (
        <Button
          key={type.id}
          variant="secondary"
          onClick={() => handleClick(type)}>
          + {type.name}
        </Button>
      ))}
    </Flex>
  );
};

export default AddButton;
