import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Tooltip,
  Divider,
  Label,
  Flex,
  FormHeading,
  Checkbox
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { ButtonComposer } from '../../../Buttons/index';

const CarouselItem = ({
  image,
  url,
  title,
  subtitle,
  image_description,
  full_size,
  responses,
  handleImageChange,
  handleUrlChange,
  handleTitleChange,
  handleSubtitleChange,
  handleResponsesChange,
  handleImageDescriptionChange,
  handleSizeChange
}) => {
  const [selectImage, setSelectImage] = useState(false);

  const toggleSelectImage = () => setSelectImage(select => !select);

  const handleFileSelect = ({ link }) => {
    handleImageChange(link);
    setSelectImage(false);
  };

  return (
    <Flex col ySpace>
      {selectImage && (
        <ImageSelect
          handleCancel={toggleSelectImage}
          handleFileSelect={handleFileSelect}
        />
      )}
      <FormHeading>Image</FormHeading>
      <Flex mb xSpace>
        <Flex col fat>
          <Flex mb>
            <Checkbox
              checked={full_size}
              onChange={() => handleSizeChange(full_size)}
              label="Full size"
              id="Full Size"
              name="Full Size"
              aria-label="Full Size"
            />
          </Flex>
          <Flex>
            <TemplatingTextInput
              value={image}
              placeholder={'http://placehold.it/400x200'}
              onChange={value => handleImageChange(value)}
            />
            <Flex shrink ml>
              <Button
                variant="secondary"
                onClick={toggleSelectImage}
                icon="document">
                Select image
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Label htmlFor="url">
        Image Link
        <Tooltip
          position={Tooltip.POSITIONS.RIGHT}
          tooltip={
            <Tooltip.Content>
              The link that a user will be taken to when the image is clicked.
            </Tooltip.Content>
          }>
          <Tooltip.Icon>?</Tooltip.Icon>
        </Tooltip>
      </Label>
      <TemplatingTextInput
        id="url"
        name="url"
        value={url}
        placeholder="https://example.com/image"
        onChange={handleUrlChange}
      />
      <Label htmlFor="image_description">Image Description</Label>
      <TemplatingTextInput
        id="image_description"
        name="image_description"
        value={image_description}
        placeholder="Picture of a tree"
        onChange={handleImageDescriptionChange}
      />
      <Divider />
      <FormHeading>Message</FormHeading>
      <Label htmlFor="title">Title</Label>
      <TemplatingTextInput
        id="title"
        name="title"
        value={title}
        onChange={handleTitleChange}
      />
      <Label htmlFor="subtitle">Subtitle</Label>
      <TemplatingTextInput
        id="subtitle"
        name="subtitle"
        value={subtitle}
        onChange={handleSubtitleChange}
      />
      <ButtonComposer value={responses} onChange={handleResponsesChange} />
    </Flex>
  );
};

CarouselItem.propTypes = {
  image: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image_description: PropTypes.string,
  full_size: PropTypes.bool,
  responses: PropTypes.array.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleUrlChange: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleSubtitleChange: PropTypes.func.isRequired,
  handleResponsesChange: PropTypes.func.isRequired,
  handleImageDescriptionChange: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func.isRequired
};

export default CarouselItem;
