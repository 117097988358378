import React, { useState } from 'react';

import {
  OneThirdLayout,
  Flex,
  TwoThirdsLayout,
  InfoSection,
  NoResults,
  IconPanel,
  Button,
  Placeholder,
  useNotification,
  useModal
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';
import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';

import WebhookModal from './WebhookModal';
import {
  createWebhook,
  editWebhook,
  deleteWebhook as deleteWebhookApi
} from '../../api/index';

const Webhooks = () => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(null);

  const { showSuccess } = useNotification();
  const { copy, copied } = useCopyToClipboard();
  const { showModal, hideModal } = useModal();

  const query = useQuery('webhooks');
  const queryClient = useQueryClient();
  const createWebhookMutation = useMutation(createWebhook, {
    onSuccess: () => {
      queryClient.invalidateQueries('webhooks');
      showSuccess('Successfully added webhook');
      setCreate(false);
    }
  });
  const editWebhookMutation = useMutation(editWebhook, {
    onSuccess: () => {
      queryClient.invalidateQueries('webhooks');
      showSuccess('Successfully updated webhook');
      setEdit(null);
    }
  });
  const { mutate: deleteWebhook } = useMutation(deleteWebhookApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('webhooks');
      showSuccess('Successfully deleted webhook');
    }
  });

  const handleCreateWebhook = form => {
    createWebhookMutation.mutate(form);
  };

  const handleEditWebhook = form => {
    editWebhookMutation.mutate({ id: edit.id, ...form });
  };

  const handleDeleteWebhook = webhook => {
    showModal({
      header: `Confirm deletion of ${webhook.name} webhook`,
      body: `Are you sure you want to delete this webhook for ${webhook.referring_domain}?`,
      handleConfirm: () => {
        try {
          deleteWebhook(webhook.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Flex>
      {create && (
        <WebhookModal
          handleClose={() => setCreate(false)}
          handleSubmit={handleCreateWebhook}
          loading={createWebhookMutation.isLoading}
        />
      )}
      {edit && (
        <WebhookModal
          handleClose={() => setEdit(null)}
          handleSubmit={handleEditWebhook}
          loading={editWebhookMutation.isLoading}
          initialWebhook={{
            name: edit.name,
            referring_domain: edit.referring_domain,
            webhook_action_type_id: edit.webhook_action_type_id,
            webhook_action_type_name: edit.webhook_action_type_name,
            variable_id: edit.variable_id
          }}
        />
      )}
      <OneThirdLayout>
        <InfoSection
          title="Webhooks"
          info="Trigger your chatbot with incoming webhooks."
          actions={
            <PermissionFilter can="edit webhooks">
              <Button onClick={() => setCreate(true)}>Add Webhook</Button>
            </PermissionFilter>
          }
        />
      </OneThirdLayout>
      <TwoThirdsLayout>
        {query.isLoading && <Placeholder />}
        {query.isSuccess && query.data.data.length === 0 && (
          <NoResults
            text="No webhooks configured"
            subtitle={'Add a webhook to start using it in conversations'}
          />
        )}
        {query.isSuccess &&
          query.data.data.length > 0 &&
          query.data.data.map(webhook => (
            <Flex col ySpace mb key={webhook.id}>
              <IconPanel
                icon={'globe'}
                title={webhook.name}
                subtitle={webhook.webhook_action_type_name}
                status={`${webhook.webhook_url}?key=${webhook.key}`}
                actions={
                  <PermissionFilter can="edit webhooks">
                    <Flex xSpace>
                      <Button
                        data-testid={`edit ${webhook.name}`}
                        onClick={() => setEdit(webhook)}
                        icon="pencilAlt">
                        Edit
                      </Button>
                      <Button
                        variant="secondary"
                        icon="clipboardCopy"
                        onClick={() => {
                          copy(`${webhook.webhook_url}?key=${webhook.key}`);
                        }}>
                        {copied ? 'Copied' : 'Copy'}
                      </Button>
                      <Button
                        data-testid={`delete ${webhook.name}`}
                        variant="secondary"
                        icon="trash"
                        colour="danger"
                        loading={deleteWebhook.isLoading}
                        onClick={() => handleDeleteWebhook(webhook)}>
                        Delete
                      </Button>
                    </Flex>
                  </PermissionFilter>
                }
              />
            </Flex>
          ))}
      </TwoThirdsLayout>
    </Flex>
  );
};

export default Webhooks;
