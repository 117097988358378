import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const FaqSelect = ({
  value,
  selectedGroups = [],
  selectedLocations = [],
  exclude = false,
  ...props
}) => {
  const { data, isLoading, isSuccess } = useQuery([
    'faqs/all',
    { groupIds: selectedGroups, locations: selectedLocations }
  ]);

  const handleData = () => {
    if (!data?.data) {
      return [];
    }

    return data.data
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .filter(item => item.id !== exclude)
      .map(formatSelectOption);
  };

  // value sometimes received as a string, so ensure is an int for equality check with faq.id
  value = value ? parseInt(value) : null;

  const option = data && value ? data.data.find(faq => faq.id === value) : null;

  return (
    <Select
      aria-label="faq-names"
      closeMenuOnSelect
      isClearable
      isLoading={isLoading}
      options={isSuccess && handleData(data)}
      value={isSuccess && option && formatSelectOption(option)}
      {...props}
    />
  );
};

FaqSelect.propTypes = {
  value: PropTypes.number,
  exclude: PropTypes.number,
  selectedGroups: PropTypes.arrayOf(PropTypes.number.isRequired),
  selectedLocations: PropTypes.array
};

export default FaqSelect;
