import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  Flex,
  Button,
  TwoThirdsLayout,
  OneThirdLayout,
  Divider
} from '@ubisend/pulse-components';
import { ConditionalTypeProvider } from '@ubisend/pulse-conditionals';
import { useAuth, PermissionFilter } from '@ubisend/pulse-auth';

import { BotSettingsProvider } from '../Providers/index';
import {
  ConverseColourSettings,
  ConverseGeneralSettings,
  DisplaySettings,
  CallToActionSettings,
  AvatarSettings,
  WidgetPreview,
  ComposerSettings,
  SettingPageSettings,
  SaveSettings,
  DeploymentCode,
  FontCode,
  ConverseWidgetSizeSettings
} from '../Components/index';
import {
  getConverseSettings,
  updateConverseLogo,
  updateConverseSettings
} from '../api/index';

const ConverseSettings = () => {
  const { client } = useAuth();

  const deployment = {
    script: `<script src="${process.env.REACT_APP_DEPLOYMENT_ROOT_URL}?c=${process.env.REACT_APP_INSTALL_NAME}&i=${client.identifier}" id="chat-widget-1925"></script>`,
    info: 'Paste this snippet in the <body> of your website.'
  };

  return (
    <PageWrapper
      header={'Converse Widget'}
      subheader={`Manage the appearance of your Converse Widget`}
      actions={
        <Flex xSpace>
          <PermissionFilter can="view broadcasts">
            <Button as={Link} variant="secondary" to="/broadcast">
              Broadcast
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view composers">
            <Button as={Link} variant="secondary" to="/composers">
              Composers
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view notifications">
            <Button as={Link} variant="secondary" to="/notifications">
              Notifications
            </Button>
          </PermissionFilter>
        </Flex>
      }>
      <Flex>
        <BotSettingsProvider
          getBotSettings={getConverseSettings}
          updateBotLogo={updateConverseLogo}
          updateBotSettings={updateConverseSettings}>
          <TwoThirdsLayout>
            <Flex mr col ySpace>
              <DeploymentCode deployment={deployment} />
              <Divider pb />
              <ConverseGeneralSettings />
              <Divider pb />
              <ConverseColourSettings />
              <Divider pb />
              <ConverseWidgetSizeSettings />
              <Divider pb />
              <FontCode />
              <Divider pb />
              <AvatarSettings />
              <Divider pb />
              <ConditionalTypeProvider>
                <CallToActionSettings />
              </ConditionalTypeProvider>
              <Divider pb />
              <ComposerSettings />
              <Divider pb />
              <ConditionalTypeProvider>
                <DisplaySettings />
                <Divider pb />
              </ConditionalTypeProvider>
              <SettingPageSettings />
              <Divider pb />
              <SaveSettings />
            </Flex>
          </TwoThirdsLayout>
          <OneThirdLayout>
            <WidgetPreview />
          </OneThirdLayout>
        </BotSettingsProvider>
      </Flex>
    </PageWrapper>
  );
};

export default ConverseSettings;
