import React, { useCallback, useRef, useEffect } from 'react';

import { useTheme } from '@ubisend/pulse-volt';

import { useCanvas, useLanguage } from '../../hooks/index';
import {
  drawRoundedRectangle,
  drawStepHeader,
  drawCircle,
  drawConditionalPreview,
  connectionPath,
  addMessagePreview,
  MESSAGE_PREVIEW_PADDING,
  STEP_HEADER_HEIGHT,
  NODE_WIDTH,
  SINGLE_ACTION_HEIGHT,
  SIDEBAR_WIDTH,
  FOOTER_PADDING_Y,
  HEADER_PADDING_Y
} from './renders/index';

// Compensate for HiDPI displays, otherwise canvas will render 1px per unit
const pixelRatio = window.devicePixelRatio || 1;

/**
 * The canvas layer is responsible for displaying all visual elements. The
 * canvas layer is broken down into util functions for rendering individual
 * elements.
 *
 * - Canvas rendering is performed using `window.requestAnimationFrame`, which
 *   tells the browser when to recalculate and paint a new frame. Using the
 *   `showPreview` field (explained more below) we know when the canvas view is
 *   static, so can stop our animation frame requests. Without this check, a new
 *   frame would be requested constantly every 16.66ms (1/60th second for
 *   60fps), greatly increasing CPU usage.
 *
 * - "constants.js" contains all the spacing and sizing information required for
 *   rendering. The canvas does not use a box model or hierarchical structure
 *   found in HTML so every reused variable for spacing is stored in this file
 *   to try and give some consistency to render functions, and eliminate the use
 *   of magic numbers.
 *
 * - Response Render functions take a CanvasContext object, render a response
 *   inside that context, and return the resulting height of rendered content.
 *   This is so the builder can keep track of the dimensions of nodes (Another
 *   gotcha of no box model), and ensure parity with the DOM layer. This is
 *   important so that click and drag events correspond correctly to their
 *   underlying nodes.
 *
 * - Conditions function similar to responses, in that they render the required
 *   elements and return the resulting height. Conditions are currently quite
 *   simple, consisting of just a black text box. This could be improved by
 *   revamping the text box util function.
 */
const Canvas = () => {
  const ref = useRef(null);

  const {
    nodes,
    links,
    getCurve,
    nodeSizes,
    panX,
    panY,
    zoomAmount,
    showPreview,
    setNodeSizes,
    getLinkDestinationOffset,
    getLinkOffset,
    newLinkOffset,
    newLinkDestinationOffset
  } = useCanvas();
  const botTheme = useTheme();
  const { language } = useLanguage();

  const getX = useCallback(
    x => {
      return x + panX.get() / zoomAmount.get();
    },
    [panX, zoomAmount]
  );

  const getY = useCallback(
    y => {
      return y + panY.get() / zoomAmount.get();
    },
    [panY, zoomAmount]
  );

  const clearTheCanvas = (context, zoomAmount) => {
    context.clearRect(
      0,
      0,
      context.canvas.width / zoomAmount.get(),
      context.canvas.height / zoomAmount.get()
    );
  };

  const addNodeCard = useCallback(
    (ctx, x, y, width, height, color) => {
      drawRoundedRectangle(
        ctx,
        getX(x),
        getY(y),
        width,
        height,
        '#dddbdf',
        color
      );
    },
    [getX, getY]
  );

  const addConnectionDot = useCallback(
    (ctx, x, y, radius, colour, stroke) => {
      drawCircle(ctx, getX(x), getY(y), radius, colour, stroke);
    },
    [getX, getY]
  );

  const addStepHeader = useCallback(
    (ctx, x, y, node) => {
      drawStepHeader(ctx, getX(x), getY(y), node, botTheme);
    },
    [getX, getY, botTheme]
  );

  const addConditionalPreview = useCallback(
    (ctx, x, y, node) => {
      return drawConditionalPreview(ctx, getX(x), getY(y), node);
    },
    [getX, getY]
  );

  const addConnectionPath = useCallback(
    (ctx, link) => {
      connectionPath(ctx, link, botTheme, panX, panY, zoomAmount, getCurve);
    },
    [getCurve, panX, panY, zoomAmount, botTheme]
  );

  const updateNodeSizes = useCallback(
    context => {
      const setHeightForNodeBlocks = (node, height) => {
        if (node.blocks.length > 0) {
          height +=
            SINGLE_ACTION_HEIGHT * node.blocks.length +
            FOOTER_PADDING_Y * (node.blocks.length + 1);
        }

        setNodeSizes(currentSizes => {
          return currentSizes
            .filter(size => size.id !== node.id || size.type !== node.type)
            .concat({
              id: node.id,
              type: node.type,
              width: NODE_WIDTH,
              height
            });
        });
        return height;
      };

      // Gets the heights of nodes by rendering them:
      // The render functions return the height of the resulting object.
      // Once we have the height, we can then clear the canvas as
      // we only want the height, not the actual render.
      nodes.forEach(node => {
        if (node.type === 'step') {
          let height = STEP_HEADER_HEIGHT;

          if (node.style === 'messageStep') {
            height += MESSAGE_PREVIEW_PADDING * 2;
            height += addMessagePreview(
              node,
              language,
              context,
              50,
              50,
              'black'
            );
          }

          if (node.blocks.length === 0 && node.style === 'automatedStep') {
            height += SINGLE_ACTION_HEIGHT + FOOTER_PADDING_Y * 2;
          }

          setHeightForNodeBlocks(node, height);
        }

        if (node.type === 'trigger' || node.type === 'transition') {
          const height = drawConditionalPreview(context, 50, 50, node);
          setHeightForNodeBlocks(node, height);
        }

        if (node.type === 'validation') {
          let height =
            drawConditionalPreview(context, 50, 50, node) +
            MESSAGE_PREVIEW_PADDING * 2;
          height += addMessagePreview(node, language, context, 50, 50, 'black');
          setHeightForNodeBlocks(node, height);
        }
      });

      // We don't actually want to display these renders, so we need to clear the canvas immediately after
      clearTheCanvas(context, zoomAmount);
    },
    [setNodeSizes, nodes, zoomAmount, language]
  );

  useEffect(() => {
    const canvas = ref.current;
    updateNodeSizes(canvas.getContext('2d'));
  }, [nodes, updateNodeSizes]);

  useEffect(() => {
    const canvas = ref.current;
    canvas.style.transform = `scale(${1 / pixelRatio})`;
    canvas.style.transformOrigin = 'top left';
    canvas.width = (window.innerWidth - SIDEBAR_WIDTH) * pixelRatio;
    canvas.height = window.innerHeight * pixelRatio;
    const context = canvas.getContext('2d');
    let animationFrameId;
    const render = () => {
      clearTheCanvas(context, zoomAmount);
      // Scale canvas units according to the current zoom
      context.setTransform(
        zoomAmount.get() * pixelRatio,
        0,
        0,
        zoomAmount.get() * pixelRatio,
        0,
        0
      );

      // Draw Links
      links.filter(Boolean).forEach(link => {
        addConnectionPath(context, link);
      });

      // Draw Nodes
      nodes.forEach(node => {
        const nodeSize = nodeSizes.find(
          info => info.id === node.id && info.type === node.type
        );
        if (!nodeSize) {
          return;
        }

        const { width, height } = nodeSize;
        const x = node.x - width / 2;
        const y = node.y - height / 2;

        // Draw a container for this node, setting the fill colour
        const fill = 'white';

        addNodeCard(context, x, y, width, height, fill);

        if (node.type === 'step') {
          addStepHeader(context, x, y, node);

          if (node.style === 'messageStep') {
            addMessagePreview(
              node,
              language,
              context,
              getX(x),
              getY(y) + STEP_HEADER_HEIGHT,
              botTheme.gradient.from
            );
          }

          // Draw incoming connectors
          links
            .filter(
              link => link.to.id === node.id && link.to.type === node.type
            )
            .forEach(link => {
              // active bottom connection dot
              addConnectionDot(
                context,
                link.to.x + getLinkDestinationOffset(link),
                link.to.y + 1,
                6,
                link.to.type === 'validation' ? botTheme.black : botTheme.grey
              );
            });

          // Draw outgoing connectors
          links
            .filter(
              link => link.from.id === node.id && link.from.type === node.type
            )
            .forEach(link => {
              addConnectionDot(
                context,
                link.from.x + getLinkOffset(link),
                link.from.y - 1,
                6,
                link.to.type === 'validation' ? botTheme.black : botTheme.grey
              );
            });

          // Draw incoming (top) unlinked connector
          addConnectionDot(
            context,
            node.x + newLinkDestinationOffset(node),
            node.y - height / 2 + 1,
            6,
            'white',
            botTheme.grey // core.gray
          );
          // Draw outgoing (bottom) unlinked connectors
          addConnectionDot(
            context,
            node.x + newLinkOffset(node),
            node.y + height / 2 - 1,
            6,
            'white',
            botTheme.grey // core.gray
          );
        }

        if (node.type === 'transition' || node.type === 'trigger') {
          addConditionalPreview(context, x, y, node);
        }

        if (node.type === 'validation') {
          const height = addConditionalPreview(context, x, y, node);

          addMessagePreview(
            node,
            language,
            context,
            getX(x),
            getY(y) + height,
            botTheme.gradient.from
          );
        }
      });

      if (showPreview) {
        animationFrameId = window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [
    showPreview,
    addNodeCard,
    addConnectionDot,
    addConnectionPath,
    addConditionalPreview,
    nodes,
    links,
    getX,
    getY,
    nodeSizes,
    zoomAmount,
    addStepHeader,
    botTheme.gradient.from,
    botTheme.danger,
    botTheme.black,
    botTheme.grey,
    getLinkDestinationOffset,
    getLinkOffset,
    newLinkDestinationOffset,
    newLinkOffset,
    panX,
    panY,
    language
  ]);

  return <canvas ref={ref} style={{ position: 'absolute' }} />;
};

export default Canvas;
