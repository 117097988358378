import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

import { useTheme } from '../../../hooks/index';

const StyledWindow = styled(motion.div)`
  ${props => `width: ${props.theme?.size ? props.theme.size.width : '24rem'};`}
  ${props =>
    `height: ${props.theme?.size ? props.theme.size.height : '560px'};`}

  *:not(h1) {
    ${props => `font-family: '${props.theme.bodyFont}', sans-serif;`}
  }

  ${tw`shadow-lg flex flex-col justify-between bg-grey-light rounded overflow-hidden`};
  max-height: 100%;
  min-height: 440px;
  @media only screen and (max-width: 420px) {
    ${tw`h-full w-full`}
  }
`;

const initial = { y: 100, opacity: 0 };
const animate = { y: 0, opacity: 1 };
const exit = { y: 100, opacity: 0 };
const transition = {
  type: 'spring',
  stiffness: 400,
  damping: 60
};

const Window = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <StyledWindow
      id="converse-custom-bot"
      data-testid="converse-window"
      initial={initial}
      transition={transition}
      animate={animate}
      exit={exit}
      theme={theme}
      {...props}>
      {children}
    </StyledWindow>
  );
};

export default Window;
