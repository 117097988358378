import React from 'react';
import PropTypes from 'prop-types';

import ComposerButton from './ComposerButton';

const BurgerButton = props => (
  <ComposerButton {...props}>
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      id="burger_button"
      style={
        props.isOpen
          ? { transform: 'rotate(45deg)', transitionDuration: '0.3s' }
          : { transitionDuration: '0.3s' }
      }>
      <path
        className="secondary"
        d="m482.485128,71.875319l-446.359,0c-18.131,0 -32.821,-14.69 -32.821,-32.82s14.69,-32.821 32.821,-32.821l446.359,0c18.13,0 32.82,14.69 32.82,32.821s-14.69,32.82 -32.82,32.82z"
        transform="matrix(0.03852631754184046,0,0,0.042411094570879514,-0.0007462456494732872,-0.08585744163936876) "
        style={
          props.isOpen
            ? {
                transformOrigin: 'left center',
                transitionDuration: '0.3s'
              }
            : { transitionDuration: '0.3s' }
        }
      />
      <path
        className="secondary"
        d="m298.690128,268.799319l-262.564,0c-18.131,0 -32.821,-14.691 -32.821,-32.821s14.69,-32.821 32.821,-32.821l262.564,0c18.13,0 32.821,14.69 32.821,32.821s-14.691,32.821 -32.821,32.821z"
        transform="matrix(0.03852631754184046,0,0,0.042411094570879514,-0.0007462456494732872,-0.08585744163936876) "
        style={
          props.isOpen ? { display: 'none' } : { transitionDuration: '0.3s' }
        }
      />
      <path
        className="white"
        d="m18.587623,11.31422l-2.023133,0c-0.698482,0 -1.264472,-0.623019 -1.264472,-1.391975s0.565952,-1.391975 1.264472,-1.391975l2.023133,0c0.698482,0 1.264434,0.623019 1.264434,1.391975s-0.565952,1.391975 -1.264434,1.391975z"
        style={
          props.isOpen ? { display: 'none' } : { transitionDuration: '0.3s' }
        }
      />
      <path
        className="secondary"
        d="m18.587623,19.66594l-17.196569,0c-0.698521,0 -1.264472,-0.623061 -1.264472,-1.391975c0,-0.768913 0.565952,-1.391975 1.264472,-1.391975l17.196569,0c0.698482,0 1.264434,0.623019 1.264434,1.391975c0,0.768913 -0.565952,1.391975 -1.264434,1.391975z"
        style={
          props.isOpen
            ? {
                transformOrigin: 'left center',
                transform: ' rotate(-90deg) translate(-11px, 2px)',
                transitionDuration: '0.3s'
              }
            : { transitionDuration: '0.3s' }
        }
      />
    </svg>
  </ComposerButton>
);

BurgerButton.propTypes = {
  isOpen: PropTypes.bool
};

export default BurgerButton;
