import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const BlockContainer = styled(motion.div)`
  ${tw`p-2 rounded flex items-center justify-start cursor-pointer`}
  color: #3251A4;
  background: #f5f8ff;
  &:hover {
    background: #e5ecfb;
  }
  & i {
    ${tw`flex`}
  }
  & svg {
    ${tw`w-6 h-6 mr-3 flex`}
  }
  & path {
    stroke-width: 2px;
    stroke: #3251a4;
  }
`;

export default BlockContainer;
