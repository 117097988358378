import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Placeholder, Flex, Heading1, Span } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';
import {
  BotProvider,
  ComposerProvider,
  ConverseMessages,
  EmbedBody,
  ConverseComposer,
  DemoDeliveryProvider,
  LanguageProvider,
  SubscriberTypingProvider,
  useQuery,
  AuthContext,
  formatSettings,
  createInitialMessages
} from '@ubisend/pulse-volt';
import { useSetupDemo } from '@ubisend/pulse-demo';

import { useBank } from '../../hooks/index';

const BASE_URL =
  process.env.NODE_ENV === 'test'
    ? 'https://localhost:3000'
    : process.env.REACT_APP_API_URL.substring(
        0,
        process.env.REACT_APP_API_URL.lastIndexOf('/api')
      );

const StyledEmbedBody = styled(EmbedBody)`
  ${tw`flex flex-col h-full flex-grow`}
`;

const EmbedDemo = () => (
  <>
    <StyledEmbedBody>
      <ConverseMessages />
    </StyledEmbedBody>
    <ComposerProvider>
      <ConverseComposer />
    </ComposerProvider>
  </>
);

const formatQueryData = data => {
  const { bot } = formatSettings(data.settings);

  return {
    language: data.language,
    bot: {
      ...bot,
      initialMessages: createInitialMessages(data.messages)
    }
  };
};

const Demo = () => {
  const { client } = useAuth();
  const { demo_trigger } = useBank();

  const [token, setToken] = useState(null);
  const [statusCode, setStatusCode] = useState();

  const mutation = useSetupDemo({
    onSuccess: ({ data, status }) => {
      setStatusCode(status);
      setToken(data.token);
    }
  });

  const query = useQuery(
    [`${BASE_URL}/bot/embed/auth`, { token, demo: true }],
    {
      // Pulse params
      token,
      clientId: client.identifier,
      browserUrl: null,
      variables: [],
      channelDriverName: 'Embedded',
      demo: true,
      // Query params
      enabled: Boolean(token),
      keepPreviousData: true,
      cacheTime: 0,
      onSuccess: data => {
        setToken(data.token);
      }
    }
  );

  useEffect(() => {
    mutation.mutate(demo_trigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.mutate, demo_trigger]);

  if (mutation.isError) {
    return (
      <Flex col fat tall center middle pad>
        <Heading1>Something went wrong</Heading1>
        <Span>Please try again later.</Span>
      </Flex>
    );
  }

  if (mutation.isSuccess && statusCode === 204) {
    return (
      <Flex col fat tall center middle pad>
        <Heading1>Demo unavailable</Heading1>
        <Span middle mt style={{ textAlign: 'center' }}>
          Workflows with automated steps are not currently available here.
        </Span>
        <Span mt>
          Please use your{' '}
          <a rel="nofollow noopener noreferrer" target="_blank" href="/demo">
            Demo
          </a>{' '}
          area.
        </Span>
      </Flex>
    );
  }

  if (!query.data || query.isLoading) {
    return (
      <Flex pad>
        <Placeholder items={1} subitems={15} />
      </Flex>
    );
  }

  const { bot, language } = formatQueryData(query.data);

  return (
    <AuthContext.Provider value={{ token: query.data.token, setToken, query }}>
      <BotProvider {...bot} name="Demo bot">
        <DemoDeliveryProvider
          client_id={client.identifier}
          channelDriverName="Embedded"
          browserUrl={null}
          variables={[]}
          base_url={BASE_URL}>
          <LanguageProvider
            initialLanguage={language}
            namespaces={['bots', 'embed']}>
            <SubscriberTypingProvider>
              <EmbedDemo />
            </SubscriberTypingProvider>
          </LanguageProvider>
        </DemoDeliveryProvider>
      </BotProvider>
    </AuthContext.Provider>
  );
};

export default Demo;
