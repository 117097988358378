import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Markdown } from '@ubisend/pulse-markdown';

import Header from '../Header';
import { BackArrowButton, Name } from '../../../Converse/Components/index';
import { useContent } from '../../../../hooks/index';

const MarkdownContainer = styled.div`
  ${tw`p-10 bg-grey-light ;flex justify-center flex-grow items-start overflow-y-auto`}

  & > div {
    ${tw`bg-white shadow w-full max-w-lg mx-auto `}
  }
`;

const Content = () => {
  const { title, content } = useContent();

  const { t } = useTranslation('bots');

  const history = useHistory();

  const handleBack = () => history.goBack();

  return (
    <>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <BackArrowButton
          aria-label={t('back_arrow_button_label')}
          onClick={handleBack}
        />
        <Name size={tw`text-xl`}>{title}</Name>
        <span />
      </Header>
      <MarkdownContainer>
        <div style={{ flexGrow: '1', padding: '1rem', overflowY: 'auto' }}>
          <Markdown>{content}</Markdown>
        </div>
      </MarkdownContainer>
    </>
  );
};

export default Content;
export { MarkdownContainer };
