import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';

import { acceptPolicy as acceptPolicyApi } from '../api/settings';
import { useWhatsAppSettings } from '../hooks';
import WhatsAppLink from './WhatsAppLink';

const WhatsAppLanding = () => {
  const { settings, isLoading } = useWhatsAppSettings();

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('whatsapp/settings');
    }
  });

  return (
    <PageWrapper center header="WhatsApp">
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} />
            </Panel>
          )}
          {!isLoading && !settings.hasGivenDataConsent && (
            <PolicyAccept
              handlePolicyAccept={acceptPolicy}
              policyUrl="https://developers.facebook.com/devpolicy/"
            />
          )}
          {!isLoading && settings.hasGivenDataConsent && <WhatsAppLink />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default WhatsAppLanding;
