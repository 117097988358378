import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Heading3, Span } from '@ubisend/pulse-components';

import LogLevel from './LogLevel';

const levels = {
  warning: 'Warning',
  success: '',
  error: 'Error'
};

const types = {
  nlp: 'NLP',
  endpoint: 'Integration',
  fatal: 'System',
  routing: 'Message Step',
  spreadsheet: 'Spreadsheet',
  semantic_search: 'Semantic Search',
  automation: 'Automated Step'
};

const getName = ({ type, level }) => {
  const name = levels[level];

  return `${types[type]} ${name}`;
};

const LogName = ({ description, ...log }) => (
  <Flex col>
    <Flex center>
      <LogLevel level={log.level} />
      <Heading3>{getName(log)}</Heading3>
    </Flex>
    <Span light>{description}</Span>
  </Flex>
);

LogName.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)).isRequired,
  level: PropTypes.oneOf(Object.keys(levels)).isRequired,
  description: PropTypes.string.isRequired
};

export { getName };
export default LogName;
