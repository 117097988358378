import React, { useState } from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

import { WhatsAppSettingContext } from '../Contexts/index';

const defaultState = {
  accessToken: null,
  wabaId: null,
  phoneNumberId: null,
  hasGivenDataConsent: false
};

const WhatsAppSettingProvider = ({ children }) => {
  const [settings, setSettings] = useState(defaultState);

  const { isLoading, refetch } = useQuery('whatsapp/settings', {
    onSuccess: ({ data }) => {
      setSettings({
        hasGivenDataConsent: data.has_given_data_consent,
        phoneNumberId: data.phone_number_id,
        wabaId: data.waba_id,
        accessToken: data.auth_token
      });
    }
  });

  const context = {
    settings,
    setSettings,
    isLoading,
    refetch
  };

  return (
    <WhatsAppSettingContext.Provider value={context}>
      {children}
    </WhatsAppSettingContext.Provider>
  );
};

export default WhatsAppSettingProvider;
