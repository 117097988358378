import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Button,
  Divider
} from '@ubisend/pulse-components';
import { useAuth, PermissionFilter } from '@ubisend/pulse-auth';

import { BotSettingsProvider } from '../Providers/index';
import {
  EmbedColourSettings,
  EmbedGeneralSettings,
  AvatarSettings,
  ComposerSettings,
  SaveSettings,
  SettingPageSettings,
  DeploymentCode,
  EmbedPreview,
  FontCode
} from '../Components/index';
import {
  getEmbedSettings,
  updateEmbedSettings,
  updateEmbedLogo
} from '../api/index';

const EmbedSettings = () => {
  const { client } = useAuth();

  const deployment = {
    script: `<iframe src="${process.env.REACT_APP_EMBED_PAGE_DEPLOYMENT_ROOT_URL}?c=${process.env.REACT_APP_INSTALL_NAME}&i=${client.identifier}" id="chat-widget-1925" style="border: none; width: 100%; height: 100%;"></iframe>`,
    info: 'Paste this snippet anywhere on your web page.'
  };

  return (
    <PageWrapper
      header={'Embedded Widget'}
      subheader={`Manage the appearance of your Embedded Widget`}
      actions={
        <Flex xSpace>
          <PermissionFilter can="view broadcasts">
            <Button as={Link} variant="secondary" to="/broadcast">
              Broadcast
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view composers">
            <Button as={Link} variant="secondary" to="/composers">
              Composers
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view notifications">
            <Button as={Link} variant="secondary" to="/notifications">
              Notifications
            </Button>
          </PermissionFilter>
        </Flex>
      }>
      <Flex>
        <BotSettingsProvider
          getBotSettings={getEmbedSettings}
          updateBotLogo={updateEmbedLogo}
          updateBotSettings={updateEmbedSettings}>
          <OneHalfLayout>
            <Flex mr col ySpace>
              <DeploymentCode deployment={deployment} />
              <Divider pb />
              <EmbedGeneralSettings />
              <Divider pb />
              <EmbedColourSettings />
              <Divider pb />
              <FontCode />
              <Divider pb />
              <AvatarSettings />
              <Divider pb />
              <ComposerSettings />
              <Divider pb />
              <SettingPageSettings />
              <Divider pb />
              <SaveSettings />
            </Flex>
          </OneHalfLayout>
          <OneHalfLayout>
            <EmbedPreview />
          </OneHalfLayout>
        </BotSettingsProvider>
      </Flex>
    </PageWrapper>
  );
};

export default EmbedSettings;
