import React, { useEffect, useState } from 'react';

import {
  Panel,
  Flex,
  InfoSection,
  OneQuarterLayout,
  TextInput,
  Label,
  Div,
  Explainer,
  Grid,
  Select,
  formatSelectOption
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks';

const FontCode = () => {
  const { settings, setSettings, focusBot, focusBanner } = useBotSettings();

  const [importsGoogleFonts, setImportsGoogleFonts] = useState(true);
  const [botFonts, setBotFonts] = useState(null);

  useEffect(() => {
    const url = settings.styles.fontSrc;

    if (!url.includes('?') || !url.includes('family=')) {
      return;
    }

    const fonts = url
      .split('?')[1]
      .split('family=')
      .map(font => {
        if (font === '') {
          return null;
        }

        if (font.includes(':')) {
          font = font.split(':')[0];
        }

        if (font.includes('&')) {
          font = font.split('&')[0];
        }

        if (font === '') {
          return null;
        }

        return {
          id: font,
          name: font.replace('+', ' ')
        };
      })
      .filter(f => f);

    setBotFonts(fonts);
  }, [settings?.styles?.fontSrc]);

  const handleFontSrcChange = event => {
    const url = event.target.value;

    focusBot();
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        fontSrc: url
      }
    }));

    setImportsGoogleFonts(
      url.startsWith('https://fonts.googleapis.com/css') &&
        url.includes('?') &&
        url.includes('family=')
    );
  };

  const handleFontChange = key => option => {
    if (key === 'headerFont') {
      focusBanner();
    } else {
      focusBot();
    }
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        [key]: option.label
      }
    }));
  };

  const selectedHeader =
    botFonts &&
    botFonts.find(option => option.name === settings.styles.headerFont);
  const selectedBody =
    botFonts &&
    botFonts.find(option => option.name === settings.styles.bodyFont);

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection title="Font" info="Set the Google font of your chatbot" />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace pb>
          <Explainer>
            You may use any{' '}
            <a
              href="https://fonts.google.com/"
              target="_blank"
              rel="noreferrer">
              Google Fonts
            </a>
            . Using an incorrect font may lead to accessibility and layout
            issues. We do not offer support for any issues caused by changing
            the font.
          </Explainer>
          <Label>Google font source</Label>
          <TextInput
            aria-label="font-src"
            value={settings.styles.fontSrc}
            onChange={handleFontSrcChange}
          />
          {!importsGoogleFonts && (
            <Explainer type="warning">
              Must begin `https://fonts.googleapis.com/css2?family=`
            </Explainer>
          )}
        </Flex>

        <Grid columns={2}>
          <Div>
            <Label htmlFor="header-font-select">Title font</Label>
            <Select
              id="header-font-select"
              aria-label="bot-header-font"
              value={selectedHeader ? formatSelectOption(selectedHeader) : null}
              options={botFonts ? botFonts.map(formatSelectOption) : []}
              onChange={handleFontChange('headerFont')}
            />
          </Div>
          <Div col>
            <Label htmlFor="bot-font-select">Bot font</Label>
            <Select
              id="bot-font-select"
              aria-label="bot-body-font"
              value={selectedBody ? formatSelectOption(selectedBody) : null}
              options={botFonts ? botFonts.map(formatSelectOption) : []}
              onChange={handleFontChange('bodyFont')}
            />
          </Div>
        </Grid>
      </Panel>
    </Flex>
  );
};

export default FontCode;
