import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  StretchPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  PanelSlider,
  TableActions,
  useModal,
  useNotification,
  OrderableTableRow,
  useOrderableTableReducer,
  usePaginationReducer,
  Pagination,
  Button
} from '@ubisend/pulse-components';

import { RoleDetails, PermissionFilter } from '../Components/index';
import {
  deleteRole as deleteRoleApi,
  updateRole as updateRoleApi,
  createRole as createRoleApi
} from '../api/index';

const RolesPage = () => {
  const [editRole, setEditRole] = useState(null);
  const [creatingRole, setCreatingRole] = useState(null);

  const order = useOrderableTableReducer({ id: 'roles' });
  const pagination = usePaginationReducer({ id: 'roles' });

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(['roles', { ...pagination.params, ...order.params }]);
  const { mutateAsync: createRole } = useMutation(createRoleApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
      showSuccess('Successfully created role');
    }
  });
  const { mutateAsync: updateRole } = useMutation(updateRoleApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
      showSuccess('Successfully updated role');
    }
  });
  const { mutateAsync: deleteRole } = useMutation(deleteRoleApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
      showSuccess(`Role was successfully deleted.`);
    }
  });

  const handleDelete = ({ id, name }) => {
    showModal({
      header: 'Remove Role',
      body: `Are you sure you want to delete the ${name} role?`,
      handleConfirm: async () => {
        try {
          await deleteRole(id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleEditRole = useCallback(
    ({ description, name, permissions: permission_ids }) => {
      updateRole({ roleId: editRole.id, description, name, permission_ids });
    },
    [editRole, updateRole]
  );

  const handleCreateRole = ({
    description,
    name,
    permissions: permission_ids
  }) => {
    createRole({ description, name, permission_ids });
    setCreatingRole(false);
  };

  return (
    <PageWrapper
      header="Roles"
      subheader="Manage and create roles"
      actions={
        <PermissionFilter can="create roles">
          <Button onClick={() => setCreatingRole(true)}>Create Role</Button>
        </PermissionFilter>
      }>
      <StretchPanel clip>
        {query.showTable && (
          <Table loading={query.isLoading} loadingColumns={4}>
            <TableHead>
              <OrderableTableRow
                rows={[
                  { label: 'Name', sort: 'name' },
                  { label: 'Description', sort: 'description' },
                  { label: 'Created', sort: 'created_at' },
                  null
                ]}
                {...order.props}
              />
            </TableHead>
            {query.isSuccess && (
              <TableBody>
                {query.data.data.map((role, key) => (
                  <TableRow key={key}>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>{role.description}</TableCell>
                    <TableCell>
                      {dayjs(role.created_at).from(dayjs())}
                    </TableCell>
                    <TableActions>
                      <PermissionFilter can="edit roles">
                        <Button
                          variant="secondary"
                          icon="pencilAlt"
                          onClick={() => setEditRole(role)}>
                          Edit
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="delete roles">
                        <Button
                          colour="danger"
                          variant="secondary"
                          icon="trash"
                          onClick={() => handleDelete(role)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )}
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
      <AnimatePresence>
        {editRole && (
          <PanelSlider
            width={'640px'}
            header={`Editing ${editRole.name}`}
            handleHide={() => setEditRole(null)}>
            <RoleDetails
              autoSave={true}
              initialName={editRole.name}
              initialDescription={editRole.description}
              initialPermissions={editRole.permissions}
              handleSubmit={handleEditRole}
            />
          </PanelSlider>
        )}
        {creatingRole && (
          <PanelSlider
            width={'640px'}
            header={`Create a new role`}
            handleHide={() => setCreatingRole(false)}>
            <RoleDetails autoSave={false} handleSubmit={handleCreateRole} />
          </PanelSlider>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default RolesPage;
