import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import {
  ConnectionStatus,
  Name,
  NotificationButton,
  CogButton,
  TicketButton
} from '../../Converse/Components/index';
import { TicketFilter, BotLogo } from '../../../Components/index';
import { useBot, useNotifications } from '../../../hooks/index';

const getSize = (name = '') => {
  if (name.length < 8) {
    return tw`text-xl`;
  }

  if (name.length < 14) {
    return tw`text-lg`;
  }

  return tw`text-base`;
};

const StyledConnectionStatus = styled(ConnectionStatus)`
  ${tw`h-6 mr-3`}
  & > *:first-child:not(:only-child) {
    ${tw`mr-2`}
  }
  & > div {
    ${tw`h-3 w-3`}
  }
  & svg {
    ${tw`w-6 h-6`}
  }
  & span {
    ${tw`text-sm`}
  }
`;

const WindowHeader = () => {
  const { t } = useTranslation('bots');

  const {
    logo,
    name,
    showLogo,
    logoLink,
    showName,
    hasActiveSettings
  } = useBot();

  const location = useLocation();

  const history = useHistory();

  const { notifications } = useNotifications();

  const toggleNotifications = () => {
    if (location.pathname === '/window/body/notifications') {
      history.push('/window/body/messages');
    } else {
      history.push('/window/body/notifications');
    }
  };

  const toggleTickets = () => {
    if (location.pathname === '/window/body/tickets') {
      history.push('/window/body/messages');
    } else {
      history.push('/window/body/tickets');
    }
  };

  const toggleSettings = () => {
    if (location.pathname === '/window/body/settings') {
      history.push('/window/body/messages');
    } else {
      history.push('/window/body/settings');
    }
  };

  return (
    <>
      <Header
        id="embed-custom-bot-header"
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <BotLogo
            showLogo={showLogo}
            logoLink={logoLink}
            logo={logo}
            style={{
              marginRight: '2rem',
              maxWidth: '100px',
              maxHeight: '40px'
            }}
          />
          {showName && <Name size={getSize(name)}>{name}</Name>}
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', flex: 0 }}>
          <StyledConnectionStatus />
          <TicketFilter>
            <TicketButton
              aria-label={t('ticket_button_label')}
              onClick={toggleTickets}
            />
          </TicketFilter>
          {notifications.length !== 0 && (
            <NotificationButton
              aria-label={t('notification_button_label')}
              scale={1.5}
              onClick={toggleNotifications}
            />
          )}
          {hasActiveSettings && (
            <CogButton
              aria-label={t('settings_header')}
              onClick={toggleSettings}
            />
          )}
        </div>
      </Header>
    </>
  );
};

export default WindowHeader;
