import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCancel,
  ModalBody,
  Flex,
  Button,
  FormGroup,
  Label,
  TextInput
} from '@ubisend/pulse-components';

import SectionSelect from './SectionSelect';

const SectionModal = ({ handleSubmit, handleClose, imports }) => {
  const [sectionLink, setSectionLink] = useState({
    section: null,
    text: ''
  });

  const handleSectionSelect = section =>
    setSectionLink({ ...sectionLink, section });

  const handleLinkTextChange = e => {
    const value = e.target.value;
    setSectionLink({ ...sectionLink, text: value });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent
          style={{
            width: '60rem',
            maxWidth: '60rem',
            maxHeight: '40rem',
            overflowY: 'auto'
          }}>
          <ModalHeader>Add a section link</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="link-text">Link text {`(optional)`}</Label>
              <TextInput
                placeholder="Click me"
                id="link-text"
                value={sectionLink.text}
                onChange={handleLinkTextChange}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="section-select">Select a section</Label>
              <SectionSelect
                id="section-select"
                value={sectionLink.section ? sectionLink.section.value : null}
                useCachedImports={Boolean(imports)}
                onChange={handleSectionSelect}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Flex right center xSpace>
              <ModalCancel onClick={() => handleClose()}>Cancel</ModalCancel>
              <Button
                disabled={!sectionLink.section}
                onClick={() => handleSubmit(sectionLink)}
                aria-label="link-section-submit">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

SectionModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  imports: PropTypes.array
};

export default SectionModal;
