import { css } from 'styled-components';
import chroma from 'chroma-js';

const styles = css`
  ${tw`cursor-pointer text-sm rounded-lg px-3 py-2 font-poppins bg-transparent w-full text-center no-underline`};
  box-sizing: border-box;
  border: 1px solid
    ${props => chroma(props.theme.bot[props.direction].text).alpha(0.3)};
  color: ${props => props.theme.bot[props.direction].text};
  transition: all ease 0.1s;
  &:hover {
    background: ${props =>
      chroma(props.theme.bot[props.direction].text).alpha(0.1)};
  }
  &:active {
    background: ${props =>
      chroma(props.theme.bot[props.direction].text).alpha(0.05)};
  }
`;

export default styles;
