import React from 'react';

import {
  Panel,
  Flex,
  InfoSection,
  OneQuarterLayout,
  Label,
  TextInput,
  Grid,
  Div,
  Explainer
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks';

const ConverseWidgetSizeSettings = () => {
  const { settings, setSettings } = useBotSettings();

  const handleDimensionChange = key => event => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        size: {
          ...settings.styles.size,
          [key]: event.target.value
        }
      }
    }));
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Bot size"
          info="Set the height and width of your chatbot"
        />
      </OneQuarterLayout>
      <Panel>
        <Explainer mb>
          The bot will remain full width and height on mobile. Default values
          are width 24rem, height 640px.
        </Explainer>
        <Grid columns={2}>
          <Div>
            <Label>Width</Label>
            <TextInput
              aria-label="converse-width"
              value={settings.styles.size ? settings.styles.size.width : ''}
              onChange={handleDimensionChange('width')}
            />
          </Div>
          <Div>
            <Label>Height</Label>
            <TextInput
              aria-label="converse-height"
              value={settings.styles.size ? settings.styles.size.height : ''}
              onChange={handleDimensionChange('height')}
            />
          </Div>
        </Grid>
      </Panel>
    </Flex>
  );
};

export default ConverseWidgetSizeSettings;
