import React, { useEffect } from 'react';
import styled from 'styled-components';

import { TextInput as BaseTextInput } from '@ubisend/pulse-components';

import { useTemplating } from '../../../hooks/index';
import { LINE_HEIGHT } from '../../../constants';

const StyledBaseTextInput = styled(BaseTextInput)`
  ${tw`pr-12`}
  white-space: pre;
  line-height: normal;
  tab-size: 4;
`;

const TextInput = props => {
  const {
    value,
    dispatch,
    lineCount,
    input,
    cursor,
    justAddedSnippet,
    TYPES
  } = useTemplating();

  useEffect(() => {
    if (justAddedSnippet.current) {
      justAddedSnippet.current = false;
      input.current.selectionStart = cursor.current;
      input.current.selectionEnd = cursor.current;
      input.current.focus();
    }
  });

  const handleValueChange = event => {
    const { value, selectionStart } = event.target;

    dispatch({
      type: TYPES.UPDATE_VALUE,
      value,
      cursor: selectionStart
    });
  };

  const handleOnClick = event => {
    const { selectionStart } = event.target;

    dispatch({ type: TYPES.UPDATE_CURSOR, cursor: selectionStart });
  };

  const handleOnKeyUp = event => {
    const selectionStart = event.target.selectionStart;

    dispatch({ type: TYPES.UPDATE_CURSOR, cursor: selectionStart });
  };

  return (
    <StyledBaseTextInput
      {...props}
      value={value}
      ref={input}
      onChange={handleValueChange}
      onClick={handleOnClick}
      onKeyUp={handleOnKeyUp}
      rows={lineCount}
    />
  );
};

export default TextInput;
