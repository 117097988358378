import React from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';
import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';

import {
  InstagramSettings,
  SetupInstagramComponent
} from '../Components/index';
import { acceptPolicy as acceptPolicyApi } from '../api/index';

const InstagramLink = () => {
  const { data, isLoading, isSuccess } = useQuery('instagram/settings');

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('instagram/settings');
    }
  });

  const hasSettings = Boolean(data?.data.auth_token);

  const PolicyCheck = () => {
    if (!data.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://developers.facebook.com/devpolicy/"
        />
      );
    }
    if (!hasSettings) {
      return <SetupInstagramComponent data-testid="instagram-settings" />;
    }

    return <InstagramSettings settings={data.data} />;
  };

  return (
    <PageWrapper center header="Instagram">
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} center />
            </Panel>
          )}
          {isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default InstagramLink;
