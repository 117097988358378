import React from 'react';

import { useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  Select,
  Label,
  Placeholder,
  useNotification
} from '@ubisend/pulse-components';

import { useWhatsAppSettings } from '../hooks/index';
import { updateSettings as updateSettingsApi } from '../api/settings';

const SelectPhoneNumber = () => {
  const { showSuccess, showError } = useNotification();
  const { settings, setSettings } = useWhatsAppSettings();

  const { mutate: updateSettings } = useMutation(updateSettingsApi, {
    onSuccess: () => showSuccess(`Successfully linked WhatsApp business phone number`),
    onError: () => showError(`Something went wrong whilst linking a WhatsApp account.`)
  });

  const handlePhoneNumberId = phoneNumberId => {
    setSettings({ ...settings, phoneNumberId });

    const { accessToken, wabaId } = settings;

    updateSettings({
      waba_id: wabaId,
      auth_token: accessToken,
      phone_number_id: phoneNumberId
    });
  }

  const { data, isLoading } = useQuery(
    [
      'whatsapp/numbers',
      {
        waba_id: settings.wabaId,
        access_token: settings.accessToken
      }
    ],
    {
      onSuccess: data => {
        if (data.data.length === 1) {
          return handlePhoneNumberId(data.data[0].id);
        }
      }
    }
  );

  return (
    <>
      {isLoading && <Placeholder />}
      {!isLoading && (
        <>
          <Label>Phone number id to use</Label>
          <Select
            style={{ flexGrow: 1, flexShrink: 0 }}
            aria-label="wa-phone-number-select"
            options={data.data.map(({ id }) => {
              return { value: id, label: id };
            })}
            onChange={option => handlePhoneNumberId(option.value)}
          />
        </>
      )}
    </>
  );
};

export default SelectPhoneNumber;
