import React, { useState } from 'react';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  Button,
  StretchPanel as Panel,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableCell,
  PanelSlider,
  useNotification,
  useModal,
  TableActions,
  NoResults
} from '@ubisend/pulse-components';
import {
  useLocations,
  createLocation as createLocationApi,
  updateLocation as updateLocationApi,
  deleteLocation as deleteLocationApi,
  useMutation,
  useQueryClient
} from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { LocationDetails } from '../Components/index';

const Locations = () => {
  const [chosenLocation, setChosenLocation] = useState();
  const [showCreateLocation, setShowCreateLocation] = useState(false);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { locations } = useLocations();

  const handleLocationEdit = location => setChosenLocation(location);

  const handleEditHide = () => setChosenLocation(null);

  const handleShowCreate = () => setShowCreateLocation(true);

  const handleCreateHide = () => setShowCreateLocation(false);

  const queryClient = useQueryClient();
  const { mutate: createLocation } = useMutation(createLocationApi, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('locations');
      showSuccess(`Successfully created "${data.data.name}".`);
      handleCreateHide();
    }
  });
  const { mutate: updateLocation } = useMutation(updateLocationApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('locations');
      showSuccess('Successfully changed location name.');
      handleEditHide();
    }
  });
  const { mutateAsync: deleteLocation } = useMutation(deleteLocationApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('locations');
    }
  });

  const handleEditSave = params => {
    updateLocation({ id: chosenLocation.id, ...params });
  };

  const handleCreateSave = params => createLocation(params);

  const handleLocationDelete = location => {
    showModal({
      header: 'Delete Location',
      body: `Are you sure you want to delete the "${location.name}" location?`,
      handleConfirm: async () => {
        try {
          await deleteLocation(location.id);
          showSuccess(`Successfully deleted "${location.name}".`);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <PageWrapper
      header="Manage Locations"
      subheader="Assign locations to FAQs and conversations"
      actions={
        <PermissionFilter can="create locations">
          <Button onClick={handleShowCreate}>Create a Location</Button>
        </PermissionFilter>
      }>
      <Panel>
        {locations?.length === 0 && (
          <NoResults
            text="No locations yet"
            subtitle="Create your first location"
          />
        )}
        {(locations?.length > 0 || !locations) && (
          <Table loading={!locations}>
            <TableHead>
              <TableRow>
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell>Number of FAQs</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            {locations && (
              <TableBody>
                {locations.map((location, key) => (
                  <TableRow key={key}>
                    <TableCell>{location.name}</TableCell>
                    <TableCell>{location.faq_count}</TableCell>
                    <TableActions>
                      <PermissionFilter can="edit locations">
                        <Button
                          variant="secondary"
                          icon="pencilAlt"
                          onClick={() => handleLocationEdit(location)}>
                          Edit
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="delete locations">
                        <Button
                          variant="secondary"
                          icon="trash"
                          colour="danger"
                          onClick={() => handleLocationDelete(location)}
                          disabled={Boolean(location.faq_count)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )}
      </Panel>
      <AnimatePresence>
        {chosenLocation && (
          <PanelSlider
            header={`Edit ${chosenLocation.name}`}
            handleHide={handleEditHide}
            width="40vw">
            <LocationDetails
              initialName={chosenLocation.name}
              handleSubmit={handleEditSave}
            />
          </PanelSlider>
        )}
        {showCreateLocation && (
          <PanelSlider
            header={`Create New Location`}
            handleHide={handleCreateHide}
            width="40vw">
            <LocationDetails handleSubmit={handleCreateSave} />
          </PanelSlider>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default Locations;
