import React from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  Flex,
  Button,
  InfoSection,
  Divider,
  OneQuarterLayout
} from '@ubisend/pulse-components';
import { MarkdownCode } from '@ubisend/pulse-markdown';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';

const DeploymentCode = ({ deployment }) => {
  const { copy, copied } = useCopyToClipboard();

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection title="Deploy" info={deployment.info} />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <MarkdownCode aria-label="deployment-code-preview">
            {deployment.script}
          </MarkdownCode>
          <Divider mtNone />
          <Flex start>
            <Button
              variant="secondary"
              icon="clipboardCopy"
              onClick={() => copy(deployment.script)}>
              {copied ? 'Copied' : 'Copy code'}
            </Button>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

DeploymentCode.propTypes = {
  deployment: PropTypes.arrayOf(
    PropTypes.shape({
      script: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired
    })
  ).isRequired
};

export default DeploymentCode;
