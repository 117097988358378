import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  useNotification,
  Panel,
  Button,
  InnerPanel,
  Label,
  SubHeader
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { removeSettings } from '../api/index';
import { useWhatsAppSettings } from '../hooks/index';

const WhatsAppSettings = () => {
  const { settings } = useWhatsAppSettings();
  const { showSuccess, showError } = useNotification();

  const queryClient = useQueryClient();
  const { mutate: deleteSettings } = useMutation(removeSettings, {
    onSuccess: () => {
      showSuccess(`Successfully unlinked WhatsApp connection`);
      queryClient.invalidateQueries('whatsapp/settings');
    },
    onError: () => {
      showError(`Something went wrong whilst unlinking WhatsApp`);
    }
  });

  const handleDeleteSettings = () => deleteSettings();

  return (
    <Panel header="WhatsApp settings" data-testid="wa-settings">
      <Flex col ySpace mt>
        <InnerPanel>
          <Flex col ySpace mb>
            <Label>Waba id</Label>
            <SubHeader>{settings.wabaId}</SubHeader>
          </Flex>
          <Flex col ySpace mt>
            <Label>Phone number id</Label>
            <SubHeader>{settings.phoneNumberId}</SubHeader>
          </Flex>
        </InnerPanel>
        <Flex>
          <Button colour="danger" onClick={handleDeleteSettings}>
            Remove connection
          </Button>
        </Flex>
      </Flex>
    </Panel>
  );
};

WhatsAppSettings.propTypes = {
  settings: PropTypes.shape({
    waba_id: PropTypes.number,
    phone_number_id: PropTypes.number
  }).isRequired,
  handleUnlinkPage: PropTypes.func.isRequired
};

export default WhatsAppSettings;
