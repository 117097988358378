import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Markdown } from '@ubisend/pulse-markdown';
import { Placeholder } from '@ubisend/pulse-components';

import Header from '../Components/Header';
import { useQuery } from '../../../hooks/index';
import { BackArrowButton, Name } from '../../Converse/Components/index';
import { MarkdownContainer } from '../Components/ResponseTypes/Content';

const Section = ({ match }) => {
  const { t } = useTranslation('bots');
  const history = useHistory();

  const { id } = match.params;

  const { isLoading, data } = useQuery(`sections/${id}`);

  const handleBack = () => history.goBack();

  return (
    <>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <BackArrowButton
          aria-label={t('back_arrow_button_label')}
          onClick={handleBack}
        />
        <Name size={tw`text-xl`}>
          {isLoading ? 'Loading...' : data.data.title}
        </Name>
        <span />
      </Header>
      <MarkdownContainer>
        <div style={{ flexGrow: '1', padding: '1rem', overflowY: 'auto' }}>
          {isLoading && <Placeholder />}
          {!isLoading && <Markdown>{data.data.content}</Markdown>}
        </div>
      </MarkdownContainer>
    </>
  );
};

export default Section;
