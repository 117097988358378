import React from 'react';

import {
  Flex,
  Panel,
  OneQuarterLayout,
  InfoSection
} from '@ubisend/pulse-components';

import InitialOpenSettings from './InitialOpenSettings';
import RenderSettings from './RenderSettings';

const DisplaySettings = () => {
  return (
    <Flex top>
      <OneQuarterLayout>
        <InfoSection
          title="Display Rules"
          info="Create smart rules to control the visibility of your chat widget."
        />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <RenderSettings />
          <InitialOpenSettings />
        </Flex>
      </Panel>
    </Flex>
  );
};

export default DisplaySettings;
