import { useContext } from 'react';

import { WhatsAppSettingContext } from '../Contexts/index';

const useWhatsAppSettings = () => {
  const context = useContext(WhatsAppSettingContext);

  return context;
};

export default useWhatsAppSettings;
