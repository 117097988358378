import React from 'react';

import { hasPermission } from '@ubisend/pulse-auth';

import { WhatsAppLanding } from './Pages/index';
import { WhatsAppSettingProvider, WhatsAppProvider } from './Providers';

const routes = [
  {
    name: 'WhatsApp',
    path: '/whatsapp-link',
    component: () => (
      <WhatsAppSettingProvider>
        <WhatsAppProvider>
          <WhatsAppLanding />
        </WhatsAppProvider>
      </WhatsAppSettingProvider>
    ),
    canAccess: hasPermission('view whatsapp settings'),
    actions: [
      {
        title: 'Manage your WhatsApp channel',
        description: 'Connect, disconnect, or edit your WhatsApp integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
