import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AnimatePresence } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

import Content from './Content';
import Button from '../Button';
import { useBelowScreenSize } from '../../../../hooks/index';

const IFrame = styled.iframe`
  ${tw`w-full flex-grow border-0 h-full`}
  box-sizing: border-box;
`;

const Embed = ({ content: contentObject }) => {
  const { content } = contentObject;

  const [showLightbox, setShowLightbox] = useState(false);

  const smallScreen = useBelowScreenSize(breakpoints.xl);

  return (
    <>
      <Content.Container layout layoutId="embed">
        {!smallScreen && (
          <Content.TopButton right onClick={() => setShowLightbox(true)} />
        )}
        <IFrame title={content.title} src={content.url} />
      </Content.Container>
      <Content.SmallScreenOverlay>
        <Button onClick={() => setShowLightbox(true)} style={{ zIndex: 11 }}>
          <Content.Expand />
        </Button>
      </Content.SmallScreenOverlay>
      <AnimatePresence exitBeforeEnter>
        {showLightbox && (
          <Content.Lightbox
            onClick={() => setShowLightbox(false)}
            layoutId="embed lightbox">
            <Content.Container
              layout
              layoutId="embed lightbox container"
              style={{ overflowY: 'hidden' }}>
              <Content.TopButton
                right
                onClick={() => setShowLightbox(false)}
                style={{ zIndex: 30 }}
              />
              <IFrame title={content.title} src={content.url} />
            </Content.Container>
          </Content.Lightbox>
        )}
      </AnimatePresence>
    </>
  );
};

Embed.propTypes = {
  content: PropTypes.shape({
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  }).isRequired
};
export default Embed;
