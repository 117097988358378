import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Flex,
  OneThirdLayout,
  TwoThirdsLayout,
  InfoSection,
  Divider,
  MiniToggle,
  Paragraph,
  Heading3,
  Tooltip,
  useTheme
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const isPreview = ({ preview }) => preview;

const isNotPreview = ({ preview }) => !preview;

const defaultCanEditDriver = () => true;

const defaultCanEnableDriver = () => true;

const defaultCanDisableDriver = () => true;

const Drivers = ({
  copy,
  columns = 3,
  type = 'single',
  value: originalValue,
  required,
  drivers,
  handleClick,
  canEditDriver = defaultCanEditDriver,
  canEnableDriver = defaultCanEnableDriver,
  canDisableDriver = defaultCanDisableDriver
}) => {
  const theme = useTheme();

  const value = type === 'multi' ? [...new Set(originalValue)] : originalValue;

  const availableDrivers = drivers.filter(isNotPreview);
  const previewDrivers = drivers.filter(isPreview);

  const isActive = driver => {
    if (type === 'multi') {
      return Boolean(value.find(item => item === driver.id));
    }

    return driver.id === value;
  };

  const isDisabledSingle = driver => {
    const active = isActive(driver);

    // Don't support disabling drivers if 1 is required
    if (active && required) {
      return true;
    }

    if (active) {
      return !canDisableDriver(driver);
    }

    if (driver.is_disabled) {
      return true;
    }

    return !canEnableDriver(driver);
  };

  const isDisabledMulti = driver => {
    const active = isActive(driver);

    // Don't support disabling drivers if 1 is required
    if (active && value.length === 1) {
      return true;
    }

    if (active) {
      return !canDisableDriver(driver);
    }

    if (driver.is_disabled) {
      return true;
    }

    return !canEnableDriver(driver);
  };

  const isDisabled = driver => {
    if (type === 'single') {
      return isDisabledSingle(driver);
    }

    return isDisabledMulti(driver);
  };

  return (
    <Flex col>
      <Flex mb>
        <OneThirdLayout>
          <InfoSection
            title={copy.available.title}
            info={copy.available.info}
          />
        </OneThirdLayout>
        <TwoThirdsLayout>
          <Grid columns={columns}>
            {availableDrivers.map((driver, key) => (
              <Card flex col tall between key={key}>
                <Flex col ySpaceSm>
                  <Flex between center>
                    {driver.logo && (
                      <Icon
                        size="1.5rem"
                        height="1.5rem"
                        width="1.5rem"
                        type={driver.logo}
                      />
                    )}
                    <Flex left>
                      <MiniToggle
                        title="driver"
                        disabled={isDisabled(driver)}
                        on={isActive(driver)}
                        handleToggleChange={() => handleClick(driver)}
                      />
                    </Flex>
                  </Flex>
                  <Heading3>{driver.name}</Heading3>
                  {driver.description && (
                    <Flex grow>
                      <Paragraph sm secondary>
                        {driver.description}
                      </Paragraph>
                    </Flex>
                  )}
                </Flex>
                {driver.link && (
                  <Flex col shiftLeft shiftRight shiftDown>
                    <Divider mbNone />
                    <Flex center between>
                      <Button
                        disabled={!canEditDriver}
                        variant="inline"
                        as={Link}
                        pad
                        to={!canEditDriver ? '#' : driver.link}>
                        Options
                      </Button>
                      {driver.warning && (
                        <Flex mr>
                          <Tooltip
                            tooltip={
                              <Tooltip.Content>
                                {driver.warning}
                              </Tooltip.Content>
                            }>
                            <Icon
                              stroke={theme.warning}
                              size="1.5rem"
                              width="1.5rem"
                              height="1.5rem"
                              type="exclamation"
                              outline
                              style={{
                                cursor: 'auto',
                                marginBottom: '-0.5rem'
                              }}
                            />
                          </Tooltip>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                )}
              </Card>
            ))}
          </Grid>
        </TwoThirdsLayout>
      </Flex>
      {previewDrivers.length > 0 && (
        <>
          <Divider />
          <Flex mt>
            <OneThirdLayout>
              <InfoSection title={copy.other.title} info={copy.other.info} />
            </OneThirdLayout>
            <TwoThirdsLayout>
              <Grid columns={3}>
                {drivers.filter(isPreview).map((driver, key) => (
                  <Card col ySpaceSm key={key}>
                    {driver.logo && (
                      <Icon
                        size="1.5rem"
                        height="1.5rem"
                        width="1.5rem"
                        type={driver.logo}
                      />
                    )}
                    <Heading3>{driver.name}</Heading3>
                    {driver.description && (
                      <Paragraph sm secondary>
                        {driver.description}
                      </Paragraph>
                    )}
                    <Paragraph sm secondary>
                      {name === 'Language'
                        ? `Contact your account manager about using ${driver.name}`
                        : `Contact your account manager to activate ${driver.name}`}
                    </Paragraph>
                  </Card>
                ))}
              </Grid>
            </TwoThirdsLayout>
          </Flex>
        </>
      )}
    </Flex>
  );
};

Drivers.propTypes = {
  copy: PropTypes.shape({
    available: PropTypes.shape({
      title: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired
    }).isRequired,
    other: PropTypes.shape({
      title: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  type: PropTypes.oneOf(['single', 'multi']).isRequired,
  required: PropTypes.bool.isRequired,
  columns: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      logo: PropTypes.string.isRequired,
      link: PropTypes.string,
      preview: PropTypes.bool.isRequired,
      is_disabled: PropTypes.bool,
      warning: PropTypes.string
    })
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
  canEditDriver: PropTypes.func,
  canEnableDriver: PropTypes.func,
  canDisableDriver: PropTypes.func
};

export default Drivers;
