import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Span,
  Button,
  useNotification,
  useModal
} from '@ubisend/pulse-components';
import { IntegrationSelect, EndpointSelect } from '@ubisend/pulse-integrations';

import Modal from '../../../Modal';
import { syncStepEndpoints } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';

const EditIntegrationBlock = ({ handleClose, node, block }) => {
  const [integration, setIntegration] = useState(block.base.integration_id);
  const [endpoint, setEndpoint] = useState(block.base.id);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { dispatch } = useBuilder();

  const { mutate, mutateAsync, isLoading } = useMutation(syncStepEndpoints, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated integrations');
      dispatch({
        type: 'SYNC_STEP_INTEGRATIONS',
        stepId: node.id,
        newIntegrations: data.data
      });
      handleClose();
    }
  });

  const handleIntegrationChange = integration => {
    setIntegration(integration ? integration.value : null);
  };

  const handleEndpointChange = endpoint => {
    setEndpoint(endpoint ? endpoint.value : null);
  };

  const valid = useMemo(() => {
    return Boolean(endpoint);
  }, [endpoint]);

  const handleSubmit = event => {
    event.preventDefault();

    if (valid && !isLoading) {
      mutate({
        stepId: node.id,
        endpoints: node.base.endpoints.map(({ id }) => {
          if (id === block.base.id) {
            return endpoint;
          }

          return id;
        })
      });
    }
  };

  const handleDelete = () => {
    showModal({
      header: 'Remove integration',
      body: `Are you sure you want to remove this integration?`,
      handleConfirm: async () => {
        try {
          await mutateAsync({
            stepId: node.id,
            endpoints: node.base.endpoints
              .filter(endpoint => endpoint.id !== block.base.id)
              .map(endpoint => endpoint.id)
          });
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Modal
      header="Edit integration"
      handleClose={handleClose}
      actions={
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete}
          loading={isLoading}>
          Delete
        </Button>
      }>
      <form onSubmit={handleSubmit}>
        <Flex col pad>
          <Flex xSpace center mb>
            <Span noWrap>Make request to</Span>
            <IntegrationSelect
              aria-label="Integration"
              value={integration}
              onChange={handleIntegrationChange}
            />
            <Span noWrap>at</Span>
            <EndpointSelect
              aria-label="Endpoint"
              value={endpoint}
              integration={integration}
              onChange={handleEndpointChange}
            />
          </Flex>
          <Flex right>
            <Button icon="save" type="submit" disabled={!valid || isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

EditIntegrationBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      endpoints: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  block: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number.isRequired,
      integration_id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default EditIntegrationBlock;
