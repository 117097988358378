import ContentInput from './ContentInput';

const contentButton = {
  id: 'contentButton',
  name: 'Content',
  Input: ContentInput,
  setInitialContent: content => {
    return {
      label: content.label || '',
      action: content.action || '',
      linkback: content.linkback || ''
    };
  },
  valid: content => {
    return Boolean(content.label && content.action);
  },
  snippet: `{"type": "contentButton", "label": "", "action": "", "linkback": ""}`
};

export default contentButton;
