import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Placeholder,
  Panel,
  UserIcon,
  Timeline,
  Profile,
  Flex,
  Label,
  Paragraph,
  AutoTextArea,
  Button,
  usePaginationReducer,
  useOrderableTableReducer,
  Pagination
} from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';
import { useAutoTextArea } from '@ubisend/pulse-component-hooks';

import { createComment } from '../api/index';

const getInitials = agent => {
  return `${agent.first_name[0]}${agent.last_name[0]}`;
};

const CommentContainer = styled.div`
  ${tw`flex`}
`;

const Comments = ({ query }) => {
  const { user } = useAuth();

  const [comment, setComment] = useState('');

  const queryClient = useQueryClient();

  const pagination = usePaginationReducer({ id: 'comments' });
  const order = useOrderableTableReducer({ id: 'comments' });

  const commentQuery = useQuery([
    query,
    { ...pagination.params, ...order.params }
  ]);
  const { mutate } = useMutation(createComment, {
    onSuccess: () => queryClient.invalidateQueries(query)
  });

  const handleSubmit = event => {
    event.preventDefault();
    mutate({ url: query, comment });
    setComment('');
  };

  const handleCommentChange = event => setComment(event.target.value);

  const ref = useAutoTextArea(comment, {
    lineHeight: 25,
    initialTextAreaHeight: 38
  });

  return (
    <Panel layout={!commentQuery.isLoading} comments>
      {commentQuery.isLoading && <Placeholder />}
      {commentQuery.isSuccess && (
        <>
          <Flex>
            {commentQuery.data.meta?.current_page === 1 && (
              <>
                <Flex mrSm col center>
                  <UserIcon layout>{getInitials(user)}</UserIcon>
                  {commentQuery.data.data.length !== 0 && <Timeline />}
                </Flex>
                <Flex col fat mb={commentQuery.data.data.length > 0}>
                  <form layout onSubmit={handleSubmit}>
                    <Flex xSpace top pb>
                      <AutoTextArea
                        aria-label="new-comment"
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Add a comment"
                        ref={ref}
                      />
                      <Button icon="pencil" type="submit" selfTop>
                        POST
                      </Button>
                    </Flex>
                  </form>
                </Flex>
              </>
            )}
          </Flex>
          <>
            {commentQuery.data.data.map((comment, key) => (
              <CommentContainer layout key={comment.id}>
                <Flex mrSm col center>
                  <UserIcon>{getInitials(comment.user)}</UserIcon>
                  {key !== commentQuery.data.data.length - 1 && <Timeline />}
                </Flex>
                <Flex col>
                  <Profile col middle>
                    <Label mb={false}>{comment.user.name}</Label>
                    <Label mb={false} secondary>
                      {dayjs(comment.created_at).from(dayjs())}
                    </Label>
                  </Profile>
                  <Flex mb={key !== commentQuery.data.data.length - 1}>
                    <Paragraph secondary>{comment.comment}</Paragraph>
                  </Flex>
                </Flex>
              </CommentContainer>
            ))}
          </>
          {commentQuery.showPagination && (
            <Pagination
              pagination={commentQuery.data.meta}
              {...pagination.props}
            />
          )}
        </>
      )}
    </Panel>
  );
};

Comments.propTypes = {
  query: PropTypes.string.isRequired
};

export default Comments;
export { getInitials, CommentContainer };
