import React from 'react';
import styled from 'styled-components';

import { AnimatePresence } from '@ubisend/framer-motion';
import { Flex } from '@ubisend/pulse-components';

import {
  Composer,
  Body,
  PoweredBy,
  BurgerMenu,
  Messages as MessagesComponent
} from '../Components/index';
import { RecordSubscriberTyping } from '../../../Components/index';
import { useDelivery, useBot, useJumpToBottom } from '../../../hooks/index';
import { ComposerProvider } from '../../../Providers/index';

const FooterDivider = styled.hr`
  ${tw`m-0 border-grey-medium border-0 border-t w-full border-solid opacity-25`}
`;

const Messages = () => {
  const { sendMessage } = useDelivery();
  const { showPoweredByUbisend, showBurgerMenu } = useBot();
  const { showJump, handleScroll } = useJumpToBottom();

  return (
    <>
      <Body onScroll={handleScroll}>
        <MessagesComponent showJump={showJump} />
      </Body>
      <AnimatePresence>
        {showBurgerMenu && <BurgerMenu handleButtonClick={sendMessage} />}
      </AnimatePresence>
      <ComposerProvider>
        <RecordSubscriberTyping />
        <Composer channel="converse" />
      </ComposerProvider>
      {showPoweredByUbisend ? (
        <>
          <FooterDivider mbNone mtNone />
          <Flex between background="white" ptSm pbSm pl pr>
            <div id="chatbot-footer" />
            <PoweredBy />
          </Flex>
        </>
      ) : (
        <>
          <Flex between background="white" pl pr>
            <div id="chatbot-footer" />
          </Flex>
        </>
      )}
    </>
  );
};

export default Messages;
