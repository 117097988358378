import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion, AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  ModalContainer,
  ModalClose,
  ModalContent,
  Heading2,
  ModalPortal,
  WizardProgress,
  Paragraph,
  Button,
  Divider,
  Panel
} from '@ubisend/pulse-components';

import {
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepFive
} from './images/index';

const FooterSection = styled.div`
  ${tw`w-full flex items-center justify-center`}
  &:not(:last-child) {
    ${tw` border-0 border-r border-solid border-grey`}
  }
`;

const CloseButton = styled(Panel)`
  ${tw`p-1 flex absolute`}
  pointer-events: auto;
  width: auto;
  top: 1rem;
  right: 1rem;
`;

const steps = [
  {
    image: stepOne,
    alt:
      'Screenshot comparison of old workflow builder vs new workflow builder',
    title: 'Welcome to the new automation builder!',
    description:
      'Automate workflows and processes with the new all-in-one builder.'
  },
  {
    image: stepTwo,
    alt: 'Screenshot of new workflow builder with automated icon',
    title: 'Introducing automated steps',
    description:
      "Use automated steps to automate processes that don't require workflows."
  },
  {
    image: stepThree,
    alt:
      'Screenshot comparison of old workflow block bank vs new workflow block bank',
    title: 'Block bank facelift',
    description:
      'New colours, interactions, and block organisation to help you build faster.'
  },
  {
    image: stepFour,
    alt: 'Screenshot comparison of new workflow accessibility changes',
    title: 'Focus on accessibility',
    description:
      'Our latest builder update focuses on increasing accessibility across the entire canvas.'
  },
  {
    image: stepFive,
    title: "Get buildin'!",
    alt:
      'Screenshot comparison of new workflow builder with bullet points of new features.',

    description:
      'Learn to use the new RPA functionalities and start automating today.'
  }
];

const WalkThrough = ({ handleClose }) => {
  const [step, setStep] = useState(0);

  const handlePrevious = () => {
    setStep(step => step - 1);
  };

  const handleNext = () => {
    setStep(step => step + 1);
  };

  const onLastStep = step === steps.length - 1;

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent style={{ maxWidth: '30rem' }}>
          <Flex
            relative
            style={{
              width: '100%',
              paddingBottom: '56.25%'
            }}
            xHidden>
            <AnimatePresence exitBeforeEnter>
              <motion.img
                key={step}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  x: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 30
                  }
                }}
                style={{ position: 'absolute', width: '100%', height: '100%' }}
                src={steps[step].image}
                alt={steps[step].alt}
              />
            </AnimatePresence>
          </Flex>
          <CloseButton>
            <ModalClose onClick={handleClose} />
          </CloseButton>
          <Flex fat col>
            <Flex pad col center xHidden>
              <Heading2 mb>{steps[step].title}</Heading2>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={step}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{
                    x: {
                      type: 'spring',
                      stiffness: 300,
                      damping: 30
                    }
                  }}>
                  <Paragraph secondary mb style={{ textAlign: 'center' }}>
                    {steps[step].description}
                  </Paragraph>
                </motion.div>
              </AnimatePresence>
              <Flex pad>
                <WizardProgress steps={steps.length} current={step} />
              </Flex>
            </Flex>
            <Divider mNone />
            <Flex>
              <FooterSection>
                <Button
                  pad
                  grow
                  middle
                  disabled={step === 0 ? true : false}
                  variant="inline"
                  onClick={handlePrevious}>
                  Previous
                </Button>
              </FooterSection>
              <FooterSection>
                <Button
                  pad
                  grow
                  middle
                  variant="inline"
                  onClick={onLastStep ? handleClose : handleNext}>
                  {onLastStep ? 'Finish' : 'Next'}
                </Button>
              </FooterSection>
            </Flex>
          </Flex>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

WalkThrough.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default WalkThrough;
