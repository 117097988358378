import React from 'react';

import {
  Panel,
  Flex,
  OneQuarterLayout,
  InfoSection,
  TabButton
} from '@ubisend/pulse-components';
import { ButtonComposer } from '@ubisend/pulse-composer';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { useLanguages, LanguagesConsumer } from '@ubisend/pulse-hooks';

import { useBotSettings } from '../../hooks/index';

const ComposerSettings = () => {
  const {
    language,
    setLanguage,
    settings,
    focusBot,
    setSettings
  } = useBotSettings();
  const { languages } = useLanguages();

  const handleButtonChange = buttonsInCurrentLanguage => {
    focusBot();

    setSettings(settings => {
      const buttons = settings.buttons
        .filter(button => button.language_id !== language.id)
        .concat(
          buttonsInCurrentLanguage.map(button => ({
            ...button,
            language_id: language.id
          }))
        );

      return { ...settings, buttons };
    });
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection title="Composer" info="Create a persistent menu." />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <LanguagesConsumer>
            <Flex>
              <AnimateSharedLayout>
                {languages.map(languageTab => (
                  <TabButton
                    onClick={() => setLanguage(languageTab)}
                    active={languageTab.id === language.id}
                    key={languageTab.id}>
                    {languageTab.name}
                  </TabButton>
                ))}
              </AnimateSharedLayout>
            </Flex>
          </LanguagesConsumer>
          <ButtonComposer
            only={['postback']}
            value={settings.buttons.filter(
              button => button.language_id === language.id
            )}
            onChange={handleButtonChange}
          />
        </Flex>
      </Panel>
    </Flex>
  );
};

export default ComposerSettings;
