import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const CallToActionContainer = styled(motion.button)`
  max-width: 226px;
  ${props => `background-color:${props.theme.bot.ctaBackgroundColour};`}
  ${props => `font-family: '${props.theme.bot.bodyFont}', sans-serif;`}
  ${tw`z-1 text-white text-left border-none flex items-center justify-center shadow rounded-lg cursor-pointer`}
  font-size: 16px;
  padding: 12px 32px 12px 12px;
  margin-bottom: 16px;
  margin-right: 16px;
  &::before {
    ${tw`block absolute`}
    width: 16px;
    height: 16px;
    ${props => props.theme.bot.ctaBackgroundColour}
    content:'';
    transform: rotate(45deg);
    bottom: -6.4px;
    right: 24px;
  }
`;

const CallToActionText = ({ children, ...props }) => (
  <CallToActionContainer
    whileHover={{ scale: 1.0375 }}
    whileTap={{ scale: 0.95 }}
    transition={{ type: 'spring', stiffness: 300, damping: 10 }}
    {...props}>
    {children}
  </CallToActionContainer>
);

export default CallToActionText;
