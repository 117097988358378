import { useContext } from 'react';

import { WhatsAppContext } from '../Contexts/index';

const useWhatsAppApi = () => {
  const context = useContext(WhatsAppContext);

  return context;
};

export default useWhatsAppApi;
