import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex } from '@ubisend/pulse-components';

import WhatsAppIcon from './WhatsAppIcon';

const LoginButton = ({ loading, ...props }) => (
  <Button
    customColour="#25D366"
    aria-label="wa-login-button"
    value="login"
    loading={loading}
    {...props}
    >
      <Flex center xSpace>
    <WhatsAppIcon style={{ marginRight: '0.5rem' }} inverted />
    {loading ? `Connecting` : `Connect to WhatsApp`}
  </Flex>
  </Button>
);

LoginButton.propTypes = {
  loading: PropTypes.bool
};

export default LoginButton;
