import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Button,
  Divider
} from '@ubisend/pulse-components';
import { useAuth, PermissionFilter } from '@ubisend/pulse-auth';

import { BotSettingsProvider } from '../Providers/index';
import {
  FullPageColourSettings,
  FullPageGeneralSettings,
  SettingPageSettings,
  SaveSettings,
  DeploymentCode,
  FullPagePreview,
  FontCode
} from '../Components/index';
import {
  getFullPageSettings,
  updateFullPageSettings,
  updateFullPageLogo
} from '../api/index';

const FullPageSettings = () => {
  const { client } = useAuth();

  const deployment = {
    script: `
   <html>
     <style>
       body, html {
         padding: 0;
         margin: 0;
       }
       iframe {
         width: 100vw;
         height: 100vh;
         border: none;
       }
     </style>
     <body>
       <iframe src="${process.env.REACT_APP_FULL_PAGE_DEPLOYMENT_ROOT_URL}?c=${process.env.REACT_APP_INSTALL_NAME}&i=${client.identifier}" id="chat-widget-1925"></iframe>
     </body>
   </html>`,
    info: 'Create a webpage with this snippet.'
  };

  return (
    <PageWrapper
      header={'Full Page Widget'}
      subheader={`Manage the appearance of your Full Page Widget`}
      actions={
        <Flex xSpace>
          <PermissionFilter can="view broadcasts">
            <Button as={Link} variant="secondary" to="/broadcast">
              Broadcast
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view composers">
            <Button as={Link} variant="secondary" to="/composers">
              Composers
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view notifications">
            <Button as={Link} variant="secondary" to="/notifications">
              Notifications
            </Button>
          </PermissionFilter>
        </Flex>
      }>
      <Flex>
        <BotSettingsProvider
          getBotSettings={getFullPageSettings}
          updateBotLogo={updateFullPageLogo}
          updateBotSettings={updateFullPageSettings}>
          <OneHalfLayout>
            <Flex mr col ySpace>
              <DeploymentCode deployment={deployment} />
              <Divider pb />
              <FullPageGeneralSettings />
              <Divider pb />
              <FullPageColourSettings />
              <Divider pb />
              <FontCode />
              <Divider pb />
              <SettingPageSettings />
              <Divider pb />
              <SaveSettings />
            </Flex>
          </OneHalfLayout>
          <OneHalfLayout>
            <FullPagePreview />
          </OneHalfLayout>
        </BotSettingsProvider>
      </Flex>
    </PageWrapper>
  );
};

export default FullPageSettings;
