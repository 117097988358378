import styled from 'styled-components';

const TextPreview = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 212px;
  max-height: 24px;
`;

export default TextPreview;
