import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { breakpoints, Flex, Span } from '@ubisend/pulse-components';
import { motion } from '@ubisend/framer-motion';
import { hexToRgba } from '@ubisend/pulse-component-hooks';
import Icon from '@ubisend/pulse-icons';

import {
  useComposer,
  useDelivery,
  useUploadFiles,
  useSendFiles,
  useDelayedBot,
  useTyping,
  useTheme
} from '../../../../../hooks/index';
import { useNotificationReducer } from '../../../../../reducers/index';
import {
  isValidMessage,
  formatClientMessage,
  formatServerMessage
} from '../../../../../utilities/index';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT,
  MAX_FILE_COUNT,
  COMBINED_MAX_FILE_BYTES
} from '../../../../../constants';
import { UploadFileInput } from '../../../../../Components/index';

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const Airplane = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
  </svg>
);

const UploadIcon = () => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke={theme.primary}>
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    </svg>
  );
};

const Input = styled(motion.input)`
  ${tw`border-0 outline-none text-base mt-2 w-full`}

  &:disabled,
  &[disabled] {
    ${tw`cursor-not-allowed opacity-0`}
  }
`;

const Container = styled(motion.div)`
  ${tw`w-full flex bg-white p-2 fixed md:relative pin-b pin-r md:shadow-lg items-start`}
  box-sizing: border-box;
  margin-left: -1rem;
  @media (min-width: ${breakpoints.md}px) {
    border-radius: 1rem;
    margin-left: 0;
  }
`;

const ComposerButton = styled(motion.button)`
  ${tw`flex p-4 border-0 rounded cursor-pointer`}
  transition: filter 0.4s ease, opacity 0.4s ease;
  background: ${({ theme }) => hexToRgba(theme.bot.gradient.from, 0.25)};

  &:disabled,
  &[disabled] {
    filter: grayscale(100%);
    ${tw`cursor-not-allowed opacity-50`}
  }

  & > svg {
    ${tw`w-6 h-6`}
    fill: ${({ theme }) => theme.bot.gradient.from};
    ${({ rotate = true }) => rotate && 'transform: rotate(90deg);'}
  }
`;

const LeftSection = styled(motion.label)`
  ${tw`w-full px-3`}
`;

const Label = styled(motion.label)`
  ${tw`block text-black text-xs mt-1 z-10`}
  transform-origin: left;
  pointer-events: none;
`;

const labelAnimations = {
  expanded: { scale: 1.25, y: 16 },
  minimised: { scale: 1, y: 0 }
};

const UploadFileButton = ({ disabled, onChange, 'aria-label': ariaLabel }) => {
  return (
    <>
      <UploadFileInput id="file" disabled={disabled} onChange={onChange} />
      <ComposerButton
        aria-label={ariaLabel}
        as="label"
        htmlFor="file"
        rotate={false}
        disabled={disabled}>
        <UploadIcon />
      </ComposerButton>
    </>
  );
};

UploadFileButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  'aria-label': PropTypes.string.isRequired
};

const MessageComposer = ({ content: passedContent }) => {
  const [inputActive, setInputActive] = useState(false);

  const inputRef = useRef();

  const notification = useNotificationReducer();
  const theme = useTheme();
  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();
  const { setMessages } = useDelayedBot();
  const typing = useTyping();

  const { t } = useTranslation(['bots', 'full_page']);

  const content = passedContent || {
    type: 'text',
    placeholder: t('message_composer_label', { ns: 'full_page' }),
    disabled: false,
    show_file_upload: false
  };

  const sendFileMutation = useSendFiles({
    onSuccess: ({ data }) => {
      if (data.messages.length > 0) {
        setMessages(messages => {
          return messages.concat(data.messages.map(formatServerMessage));
        });
      } else {
        typing.dispatch({ type: typing.TYPES.STOP_TYPING });
      }
    },
    onError: () => {
      typing.dispatch({ type: typing.TYPES.STOP_TYPING });

      notification.dispatch({
        type: notification.TYPES.SHOW_ERROR,
        message: t('processing_file_error_message', { ns: 'bots' })
      });
    }
  });
  const uploadFileMutation = useUploadFiles({
    onSuccess: files => {
      setMessages(messages => {
        return messages.concat(
          files.map(file => {
            return formatClientMessage({
              type: 'standard',
              content: { text: file.file.name }
            });
          })
        );
      });

      sendFileMutation.mutate({
        files: files.map(file => file.path)
      });
    },
    onError: () => {
      typing.dispatch({ type: typing.TYPES.STOP_TYPING });

      notification.dispatch({
        type: notification.TYPES.SHOW_ERROR,
        message: t('upload_file_error_message', { ns: 'bots' })
      });
    }
  });

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleNewReply = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    if (content.type === 'password') {
      sendMessage(
        // Replace message with asterisks.
        new Array(validatedMessage.length).fill('*').join(''),
        validatedMessage
      );
    } else {
      sendMessage(validatedMessage);
    }

    setReply('');
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 13) {
      handleNewReply();
    }
  };

  const handleBlur = () => {
    if (inputActive && reply.length === 0) {
      setInputActive(false);
    }
  };

  const handleFocus = () => {
    if (!inputActive) {
      setInputActive(true);
    }
  };

  const handleFileSelect = event => {
    if (event.target.files.length > MAX_FILE_COUNT) {
      return notification.dispatch({
        type: notification.TYPES.SHOW_WARNING,
        message: t('file_count_exceeded_error_message', { ns: 'bots' })
      });
    }

    const combinedFileSize = [...event.target.files].reduce((size, file) => {
      return size + file.size;
    }, 0);

    if (combinedFileSize > COMBINED_MAX_FILE_BYTES) {
      return notification.dispatch({
        type: notification.TYPES.SHOW_WARNING,
        message: t('file_size_exceeded_error_message', { ns: 'bots' })
      });
    }

    typing.dispatch({
      type: typing.TYPES.START_TYPING,
      message: t('uploading_files_label', { ns: 'bots' })
    });

    notification.dispatch({
      type: notification.TYPES.HIDE_NOTIFICATION
    });

    uploadFileMutation.mutate(event.target.files);
  };

  const getLabelAnimateProp = () => {
    if (notification.hasNotification) {
      return 'minimised';
    }

    return inputActive ? 'minimised' : 'expanded';
  };

  const types = {
    [notification.NOTIFICATION_TYPES.WARNING]: {
      icon: 'exclamation',
      colour: theme.warning
    },
    [notification.NOTIFICATION_TYPES.ERROR]: {
      icon: 'exclamation',
      colour: theme.danger
    }
  };

  return (
    <Container>
      <LeftSection htmlFor="message-composer">
        <Label
          htmlFor="message-composer"
          initial="expanded"
          variants={labelAnimations}
          animate={getLabelAnimateProp()}>
          {notification.hasNotification ? (
            <Flex xSpaceSm center>
              <Icon
                type={types[notification.notification.type].icon}
                colour={types[notification.notification.type].colour}
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
              />
              <Span>{notification.notification.message}</Span>
            </Flex>
          ) : (
            content.placeholder
          )}
        </Label>
        <Input
          ref={inputRef}
          id="message-composer"
          type={content.type}
          value={reply}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          disabled={content.disabled}
        />
      </LeftSection>
      <Flex xSpaceSm>
        {content.show_file_upload && (
          <UploadFileButton
            aria-label={t('upload_button_label', { ns: 'bots' })}
            onChange={handleFileSelect}
            disabled={
              uploadFileMutation.isDisabled ||
              sendFileMutation.isLoading ||
              uploadFileMutation.isLoading
            }
          />
        )}
        <ComposerButton
          aria-label={t('send_button_label', { ns: 'bots' })}
          disabled={!isValid(reply)}
          onClick={handleNewReply}>
          <Airplane />
        </ComposerButton>
      </Flex>
    </Container>
  );
};

MessageComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    show_file_upload: PropTypes.bool
  }),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default MessageComposer;
export {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Input,
  Label,
  labelAnimations
};
