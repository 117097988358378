const languages = [
  'en', // English
  'de', // German
  'nl', // Dutch
  'fr', // French
  'vi', // Vietnamese
  'pt', // Portuguese
  'tr', // Turkish
  'es', // Spanish
  'pt-br' // Portuguese Spanish
];

export default languages;
