import React, { useState } from 'react';

import {
  PageWrapper,
  Panel,
  OneHalfLayout,
  Explainer,
  useNotification,
  Select,
  Label,
  Flex,
  Button
} from '@ubisend/pulse-components';
import { useQuery, useMutation } from '@ubisend/pulse-hooks';

import { updateBlocklistSettings } from '../api';

const options = [
  { value: 'pass', label: 'Function as normal' },
  { value: 'ignore', label: 'Do not respond' },
  {
    value: 'redact',
    label: 'Function as normal, but redact the words from logs and transcripts'
  }
];

const Blocklist = () => {
  const { showNotification } = useNotification();
  const [action, setAction] = useState();
  const { isLoading } = useQuery('block-list', {
    onSuccess: ({ data }) => {
      setAction(data.action);
    }
  });
  const { mutate, isLoading: isUpdating } = useMutation(
    updateBlocklistSettings,
    {
      onSuccess: () =>
        showNotification({ message: 'Block list successfully updated.' })
    }
  );

  const handleActionChange = value => {
    setAction(value.value);
  };

  const handleBlockListSubmit = () => {
    mutate({ action });
  };

  return (
    <PageWrapper header="Blocklist" subheader="Manage your blocklist settings">
      <OneHalfLayout>
        <Panel loading={isLoading} loadingItems={1} divider header="Settings">
          <Explainer mt>
            Choose how your bot should react when a user sends an explicit
            message. Either continue as normal, ignore the message entirely or
            redact the explicit text from the message.
          </Explainer>
          <Flex col mt>
            <Label htmlFor="action">Reaction to explicit messages</Label>
            <Select
              value={options.find(option => option.value === action)}
              isLoading={isLoading}
              options={options}
              onChange={handleActionChange}
              id="action"
              name="action"
            />
          </Flex>
          <Flex right mt>
            <Button
              icon="save"
              onClick={handleBlockListSubmit}
              loading={isUpdating}>
              Save
            </Button>
          </Flex>
        </Panel>
      </OneHalfLayout>
    </PageWrapper>
  );
};

export default Blocklist;
