import React, { useState, useEffect } from 'react';

import { LoadingContainer } from '@ubisend/pulse-components';

import { WhatsAppContext } from '../Contexts/index';
import { src, version, appId } from '../whatsapp';

const addWhatsAppToWindow = () => {
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      window.FB.init({ 
        appId: appId, 
        cookie: true,
        xfbml: true,
        version: version 
      });
      resolve(window.FB);
    };
  });
};

const WhatsAppProvider = ({ children }) => {
  const [data, setData] = useState();

  const setupWhatsApp = async script => {
    const promise = addWhatsAppToWindow();

    script.src = src;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    const FB = await promise;

    setData(FB);
  };

  useEffect(() => {
    const script = document.createElement('script');

    setupWhatsApp(script);

    return () => {
      document.body.removeChild(script);

      if (window.waAsyncInit) {
        delete window.waAsyncInit;
      }
 
      if (window.FB) {
        delete window.FB;
      }
    };
  }, []);

  if (!data) {
    return <LoadingContainer />;
  }

  return (
    <WhatsAppContext.Provider value={data}>{children}</WhatsAppContext.Provider>
  );
};

export default WhatsAppProvider;
