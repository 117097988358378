import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { pseudoStyles } from '@ubisend/pulse-components';

import styles from './styles';

const StyledButton = styled.div`
  ${styles}
  ${pseudoStyles}
`;

const ContentButton = ({ response, handleButtonClick, ...props }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/window/section/${response.action}`);

    if (response.linkback) {
      handleButtonClick(response.label, response.linkback);
    }

    return true;
  };

  return (
    <StyledButton {...props} colour="white" onClick={handleClick}>
      {response.label}
    </StyledButton>
  );
};

ContentButton.propTypes = {
  response: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default ContentButton;
