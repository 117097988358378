import React from 'react';

import { Select, Label, Placeholder } from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

import { useWhatsAppSettings } from '../hooks/index';

const SelectWaba = () => {
  const { settings, setSettings } = useWhatsAppSettings();

  const handleWabaId = id => {
    setSettings({ ...settings, wabaId: id });
  };

  const { data, isLoading } = useQuery(
    [
      'whatsapp/businesses',
      {
        input_token: settings.accessToken
      }
    ],
    {
      onSuccess: data => {
        if (data.length === 1) {
          handleWabaId(data[0]);
        }
      },
      onError: () => {
        // show notification
      }
    }
  );

  return (
    <>
      {isLoading && <Placeholder />}
      {!isLoading && (
        <>
          <Label>WhatsApp Business Account Id to connect</Label>
          <Select
            style={{ flexGrow: 1, flexShrink: 0 }}
            aria-label="wa-waba-select"
            options={data.map(data => {
              return { value: data, label: data };
            })}
            onChange={option => handleWabaId(option.value)}
          />
        </>
      )}
    </>
  );
};

export default SelectWaba;
