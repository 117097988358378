import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex, Explainer } from '@ubisend/pulse-components';

import LoginButton from './LoginButton';
import { useWhatsAppApi } from '../hooks/index';
import { scope as waScope } from '../whatsapp';
import { useWhatsAppSettings } from '../hooks/index';

const WhatsAppLogin = ({ scope = waScope }) => {
  const [connecting, setConnecting] = useState();
  const { settings, setSettings } = useWhatsAppSettings();

  const handleAccessToken = accessToken => {
    setSettings({ ...settings, accessToken });
  };

  const FB = useWhatsAppApi();

  const handleLogin = () => {
    setConnecting(true);
    FB.login(
      response => {
        if (response?.authResponse) {
          const {
            authResponse: { accessToken }
          } = response;

          handleAccessToken(accessToken);
        } else {
          // handle error
        }
        setConnecting(false);
      },
      {
        scope: scope,
        extras: {
          feature: 'whatsapp_embedded_signup'
        }
      }
    );
  };

  return (
    <Flex col>
      <Explainer>
        Clicking the button below will open a WhatsApp login page to begin the
        connection process.
      </Explainer>
      <Flex top mt>
        <LoginButton onClick={handleLogin} loading={connecting} />
      </Flex>
    </Flex>
  );
};

WhatsAppLogin.propTypes = {
  scope: PropTypes.string.isRequired
};

export default WhatsAppLogin;
