import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';
import { useDevice } from '@ubisend/pulse-component-hooks';

import { ModalClose } from '../Modal/index';
import Slider from './Slider';
import { Panel } from '../Panel/index';

const MaxHeightPanel = styled(Panel)`
  ${tw`h-screen overflow-y-auto transform z-30`}
  ${props =>
    props.from === 'right'
      ? tw`rounded-tr-none rounded-br-none`
      : tw`rounded-tl-none rounded-bl-none`};
`;

const Background = styled(motion.aside)`
  ${tw`fixed pin-t pin-l w-screen h-screen flex flex-col items-center justify-center`};
  z-index: ${props => (props.from === 'right' ? 70 : 30)};
  background: ${({ theme }) => chroma(theme.primary).darken(3.25).alpha(0.66)};
`;

const PanelSlider = ({
  header,
  handleHide,
  children,
  actions,
  divider = true,
  width = '80vw',
  Panel = MaxHeightPanel,
  from = 'right',
  ...rest
}) => {
  const { isMobile } = useDevice();
  return (
    <>
      <Background
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
        from={from}
        {...rest}
        onClick={handleHide}
      />
      <Slider {...rest} from={from} width={isMobile ? '100%' : width}>
        <Panel
          divider={divider}
          header={header}
          from={from}
          actions={
            actions ? (
              actions
            ) : (
              <ModalClose onClick={handleHide} aria-label="Close slideout" />
            )
          }>
          {children}
        </Panel>
      </Slider>
    </>
  );
};

PanelSlider.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  handleHide: PropTypes.func.isRequired,
  actions: PropTypes.element,
  divider: PropTypes.bool,
  width: PropTypes.string,
  Panel: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  from: PropTypes.string
};

export default PanelSlider;
