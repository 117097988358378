import React, { useCallback } from 'react';

import { Label, FormGroup, FormHeading } from '@ubisend/pulse-components';
import { WysiwygEditor } from '@ubisend/pulse-edit';
import {
  TemplatingTextInput,
  TemplatingTextArea
} from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';

const ContentInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleTextChange = value => {
    dispatch({ type: 'UPDATE_CONTENT', newContent: { text: value } });
  };

  const handleTitleChange = value => {
    dispatch({ type: 'UPDATE_CONTENT', newContent: { title: value } });
  };

  const handleContentChange = useCallback(
    value => {
      dispatch({ type: 'UPDATE_CONTENT', newContent: { markdown: value } });
    },
    [dispatch]
  );

  return (
    <>
      <FormGroup>
        <Label htmlFor="message">Message</Label>
        <TemplatingTextArea
          id="message"
          name="message"
          value={content.text}
          onChange={handleTextChange}
        />
      </FormGroup>
      <FormHeading>Content</FormHeading>
      <FormGroup>
        <Label htmlFor="title">Title</Label>
        <TemplatingTextInput
          id="title"
          name="title"
          value={content.title}
          onChange={handleTitleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label>Body</Label>
        <WysiwygEditor
          value={content.markdown}
          onChange={handleContentChange}
          aria-label="body"
        />
      </FormGroup>
    </>
  );
};

export default ContentInput;
