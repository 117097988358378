import React from 'react';

const WhatsAppSettingContext = React.createContext({
  settings: null,
  setSettings: () => {},
  refetch: () => {},
  isLoading: null
});

export default WhatsAppSettingContext;
