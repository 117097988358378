import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../Header';
import { BackArrowButton, Name } from '../../../Converse/Components/index';
import { useMessage } from '../../../../hooks/index';

const Embed = () => {
  const message = useMessage();

  const { t } = useTranslation('bots');

  const history = useHistory();

  const handleBack = () => history.goBack();

  return (
    <>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <BackArrowButton
          aria-label={t('back_arrow_button_label')}
          onClick={handleBack}
        />
        <Name size={tw`text-xl`}>{message.content.title}</Name>
        <span />
      </Header>
      <div style={{ flexGrow: '1', padding: '1rem', overflowY: 'auto' }}>
        <iframe
          title={message.content.title}
          frameBorder="0"
          src={message.content.url}
          allowFullScreen={true}
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  );
};

export default Embed;
