import React from 'react';
import { useTranslation } from 'react-i18next';

import { Span } from '@ubisend/pulse-components';

import { useDelivery, useQuery } from '../../hooks/index';

// import { VERSION } from '../../constants';

// when this is changed before deployment, be sure to update the corrisponding test in pulse-volt Settings.test.js 'can view version'
// comments on what to change are inside the test
const VERSION = ' RPA-BUILDER-MU.5';

const Version = () => {
  const { base_url } = useDelivery();

  const { t } = useTranslation('bots');

  const { data, isSuccess } = useQuery('pulse', { baseUrl: `${base_url}/api` });

  if (!isSuccess) {
    return null;
  }

  return (
    <Span tinyText>
      {t('setting_version_label')} {VERSION} ({data.version})
    </Span>
  );
};

export default Version;
