import React from 'react';

import { Flex } from '@ubisend/pulse-components';

import { WhatsAppSettings, SetupWhatsAppComponent } from '../Components/index';
import { useWhatsAppSettings } from '../hooks/index';

const WhatsAppLink = () => {
  const { settings } = useWhatsAppSettings();

  return (
    <Flex data-testid="wa-link" mr>
      {settings.phoneNumberId ? (
        <WhatsAppSettings />
      ) : (
        <SetupWhatsAppComponent />
      )}
    </Flex>
  );
};

export default WhatsAppLink;
